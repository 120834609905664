import React, { useEffect, useState, useContext } from "react";
import { getAllAddedProducts } from "../../Networking/ProductApisActions";
import { ProductStates } from "../../Context/ProductContext";
import SearchBar from '../../acsComponents/SearchBar'
import Pagination from "../../acsComponents/Pagination";
import { Link, useLocation } from "react-router-dom";
import Loader from '../../acsComponents/Loader'


const GetProducts = () => {
  const productContext = useContext(ProductStates);
  const [searchInput, setSearchInput] = useState("")
  const [loading, setLoading] = useState(false)
  const [updatemesg, setUpdateMesg] = useState('')

  const location = useLocation()

  const updateMessage = location.state?.data
   
  

  useEffect(() => {
    if(updateMessage) {
      setUpdateMesg(updateMessage)
      setTimeout(() => setUpdateMesg(''),3000)
    }
  },[updateMessage])
  


  const handleSearch = (value) => setSearchInput(value.toLowerCase())

  const filteredProducts = productContext.getproducts?.filter(item => 
    item.product.toLowerCase().includes(searchInput)
  )

  const tableHeads = [
    "Product Name",
    "Product Brand",
    "Product Color",
    "Product Size",
    "Product Unit",
    "Discount Price",
    "Rating",
    "Main Image",
  ]

  const getdata = () => {
    setLoading(true)
    getAllAddedProducts().then((response) => {
      const data = response.data.data;
      productContext.getProductList(data);
      setLoading(false)
    });
  };
  useEffect(() => {
    getdata();
  }, []);

  return <div className="p-2">
 {updatemesg && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{updatemesg}</p>}
 <div className='p-2 flex justify-between items-center'>
          {loading && <Loader className={loading ? 'blur' : 'blur-none'} />}  
            <div className='ms-5 mt-4 w-80'>
            <SearchBar placeholder="Search product Name" onInputChange={handleSearch} />
            </div>
            <Link to='/products/AddProduct'><button className='mt-4 me-24 bg-dark-brown w-36 h-9 rounded text-xs text-white font-inter font-semibold border shadow-xl'>Add Product</button></Link>
          </div>
  <div className=" w-full mt-4 no-scrollbar overflow-y-auto bg-white shadow-lg rounded-b-lg h-96 ">       
            <div className="sticky top-0 ">
              <div className="flex justify-center items-center">
              {tableHeads.length > 0 ? (
                tableHeads.map((heading) => (
                  <div 
                    key={heading}
                    className="flex-1 flex items-center justify-center bg-light-brown h-16 font-inter text-xs text-center font-medium "
                  >
                    {heading}
                  </div>
                ))
              ): <div
                    className="flex-1 flex items-center justify-center bg-light-brown h-16 font-inter text-xs text-center font-medium "
                  >No data found
                  </div> }
                
              </div>
            </div>
            <div>
            <Pagination data={filteredProducts} justifyContent='end'>
            {filteredProducts?.length > 0 ? (
                filteredProducts.map(content => (
                <div
                  key={content.id}
                  className="border-b  h-14 flex border-border-gray-outline"
                >
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-dark-brown">
                    {content.product ? content.product : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs">
                    {content.brand ? content.brand : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs">
                    {content.color ? content.color : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs">
                    {content.productSize ? content.productSize : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs">
                    {content.unit ? content.unit : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs">
                    {content.discountedPrice ? content.discountedPrice : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs">
                    {content.rating ? content.rating : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs">
                    <img src={content.mainImage} alt='Main Image' className="h-9"/>
                  </div>              
                </div>
              ))
            ) : <div className="border-b  h-16 flex border-border-gray-outline justify-center items-center text-dark-brown"
                >No products found</div>}
                </Pagination>
            
            </div>        
        </div>  
    
  </div>;
};
export default GetProducts;
