import React, { useState } from "react";

function DropdownComponent({ children, dropmenuContent }) {
  const [dropItems, setDropItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoverItem, setHoverItem] = useState(null);

  const showDropItem = () => {
    setDropItems(true);
  };

  const hideDropItem = () => {
    setDropItems(false);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    hideDropItem();
  };

  const handleHoverItem = (item) => {
    setHoverItem(item);
  };

  const displayValue = selectedItem || dropmenuContent

  return (
    <>
      <div
        style={{
          margin: '15px',
          border: "1px #00000050 solid",
          borderRadius: "5px",
          fontFamily:'sans-serif'
        }}
      >
        <div style={{ position: 'relative', justifyContent: 'space-between', padding: '7px 0px', display: 'flex' }}>
          <div
            style={{
              margin: '4px 0px 4px 15px',
              opacity: "0.5",
              border: "none",
              background: "transparent",
              cursor: "pointer",
              letterSpacing: '2%',
              fontSize: '18px'
            }}
            onClick={dropItems ? hideDropItem : showDropItem}
          >
            {displayValue}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginRight: '10px',
              cursor: "pointer",
              opacity: "0.5",
            }}
            onClick={dropItems ? hideDropItem : showDropItem}
          >
            {dropItems ? (
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33984 14.3405L12.3398 9.34045L17.3398 14.3405L7.33984 14.3405Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="25"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.47168 0.470703L5.47168 5.4707L10.4717 0.470703L0.47168 0.470703Z"
                  fill="black"
                />
              </svg>
            )}
          </div>

          <div
            style={{
              top: '47px',
              position: "absolute",
              width: "100%",
              zIndex: "10000",
              background: "white",
              boxShadow: "0px 4px 4px 0px #00000040",
              transition: "transform 0.2s ease-out",
              transform: dropItems ? "scaleY(1)" : "scaleY(0)",
              transformOrigin: "top",
              overflow: "hidden",
            }}
          >
            {React.Children.map(children, (child, index) => (
              <div
                key={index}
                style={{
                  paddingTop: '6px',
                  paddingBottom: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                  cursor: "pointer",
                  fontSize: '15px',
                  backgroundColor:
                    hoverItem === child.props.children ? "#d3d3d35c" : "transparent",
                  color:
                    hoverItem === child.props.children ? "#007bff" : "black",
                }}
                onMouseEnter={() => handleHoverItem(child.props.children)}
                onClick={() => handleItemClick(child.props.children)}
              >
                {child}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default DropdownComponent;