import React, { useState, useEffect, useContext } from 'react';
import { EditPen, deletered } from '../../assets/svg/index';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchBar from '../../acsComponents/SearchBar';
import { deleteBrands, getAllBrands } from '../../Networking/ProductApisActions';
import { ProductStates } from '../../Context/ProductContext';
import Loader from '../../acsComponents/Loader';

const Brand = () => {
    const [hoveredBrandId, setHoveredBrandId] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [modalWindow, setModalWindow] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
    const [message, setMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setloading] = useState(false)
    const [updateMessage, setUpdateMessage] = useState("")
 
    const navigate = useNavigate();
    const location = useLocation()
    const productContext = useContext(ProductStates);

    const updateResponseMeg = location.state?.data
    useEffect(() => {
        if(updateResponseMeg) {
            setUpdateMessage(updateResponseMeg)
            setTimeout(() => setUpdateMessage(''),3000)
        }
    },[updateResponseMeg])

    const fetchBrands = () => {
        setloading(true)
        getAllBrands().then(response => {
            const data = response?.data?.data;
            productContext.Brandlist(data);
            setloading(false)
        })
    };

    const handleModalWindow = (id) => {
        setModalWindow(true);
        setSelectedId(id);
    };
    const onEditClick = (id) => {
        productContext.editBrandId(id)
        navigate('/UpdateAddBrand')
    }

    const onDeleteClick = () => {
      
        if (selectedId !== null) {
            setloading(true)   
            deleteBrands(selectedId).then((response) => {                             
                if(response.data.code === 200) {                    
                    setMessage(response.data.message)
                    setTimeout(() => setMessage(null), 3000)
                    fetchBrands();
                    setloading(false)
                    setModalWindow(false);
                }
                if(response.data.code !== 200){
                    setErrorMessage(response.data.message);
                    setloading(false)
                    setTimeout(() => setErrorMessage(null),3000)
                }             
            })
             
        }
    };

    const handleDeleteConfirmation = () => {
        setIsDeleteConfirmed(true);
    };

    useEffect(() => {
        if (isDeleteConfirmed) {
            onDeleteClick();
            setIsDeleteConfirmed(false);
        }
    }, [isDeleteConfirmed]);

    const handleBrandSearch = (value) => setSearchInput(value.toLowerCase());

    useEffect(() => fetchBrands(), []);

    const filteredBrands = productContext.getBrandList?.filter(item =>
        item.brandName.toLowerCase().includes(searchInput)
    );

    return (        
        <div className='px-10 py-2 font-inter h-screen overflow-auto no-scrollbar'>
          {updateMessage && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{updateMessage}</p>}
          {message && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{message}</p>}
          {errorMessage && <p className='text-end text-xs text-red-600 font-inter font-semibold'>{errorMessage}</p>}
          {loading && <Loader className={loading ? 'blur' : 'blur-none'} />}
            <div className="space-y-3">
                <div className="capitalize text-dark-brown text-18px font-inter font-bold">Brands</div>
                <div className="bg-white shadow-md font-inter py-5">
                    <div className="grid md:flex justify-between ps-5 pe-5 pt-4 pb-4 ">
                        <SearchBar placeholder='Search Brand Name' onInputChange={handleBrandSearch} />
                        <div className="pt-3 md:pt-0">
                            <Link to='/Addbrand'>
                                <button className='bg-dark-brown text-white font-inter  font-bold text-13px h-9 w-28 rounded-sm'>Add Brands</button>
                            </Link>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 ps-5 pe-5 font-inter ">
                        {filteredBrands?.length > 0 ? (
                            filteredBrands.map(item => (
                                <div
                                    key={item.id}
                                    className="px-4 py-3 flex flex-col items-center text-center border rounded-md shadow-sm pb-5 "
                                    onMouseEnter={() => setHoveredBrandId(item.id)}
                                    onMouseLeave={() => setHoveredBrandId(null)}
                                >
                                    <div className="flex gap-1 w-full justify-end h-4">
                                        <img className={`${hoveredBrandId === item.id ? 'block h-5' : 'hidden'} cursor-pointer`} src={EditPen} alt="Edit" onClick={() => onEditClick(item.id)} />
                                        <img className={`${hoveredBrandId === item.id ? 'block h-5' : 'hidden'} cursor-pointer`} src={deletered} alt="Delete" onClick={() => handleModalWindow(item.id)} />
                                    </div>
                                    <img src={item.imageUrl ? item.imageUrl: null } alt={item.brandName} className="h-24 w-24 object-contain mb-0" />
                                    <div className="font-bold text-15px mt-1">{item.brandName ? item.brandName : 'N/A'}</div>
                                    <div className="text-brand-green font-inter text-14px font-medium mt-1">{item.productCount ? item.productCount : null} products</div>
                                </div>
                            ))
                        ) : (
                            <div className='text-center font-inter font-bold text-dark-brown-70-opacity tracking-widest px-16 text-nowrap'>No brands available</div>
                        )}
                    </div>
                </div>
            </div>
            {modalWindow && (
                <div className='fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50'>
                    <div className='bg-white p-6 rounded shadow-lg'>
                        <p className='font-inter font-semibold text-13px '>Are you sure you want to delete this item? This action cannot be undone.</p>
                        <div className='flex justify-end space-x-4 mt-4'>
                            <button onClick={() => setModalWindow(false)} className='bg-gray-300 px-3 py-2 rounded text-13px'>Cancel</button>
                            <button onClick={handleDeleteConfirmation} className='bg-red-500 text-white px-3 py-2 rounded text-13px'>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Brand;
