import { useState } from "react"

const Typeahead = ({ fetchSuggestions }) => {
    const [inputValue, setInputValue] = useState("")
    const [filteredSuggestions, setFilteredSuggestions] = useState([])
    const [showSuggestions, setShowSuggestions] = useState(false)

    const handleInput = async (e) => {
        const value = e.target.value
        setInputValue(value)

        if (value) {
            const suggestions = await fetchSuggestions(value)
            setFilteredSuggestions(suggestions)
            setShowSuggestions(true)
        } else {
            setFilteredSuggestions([])
            setShowSuggestions(false)
        }
    }

    const handleSelectedSuggestion = (value) => {
        setInputValue(value)
        setShowSuggestions(false)
    }

    const handleBlur = () => {
        setTimeout(() => {
            setShowSuggestions(false)
        }, 500)
    }

    return (
        <div>
            <input 
                type="text" 
                value={inputValue} 
                onChange={handleInput} 
                onBlur={handleBlur} 
                placeholder="Search Name..." 
                className='appearance-none border py-1 px-2 text-gray-700 text-xs focus:outline-none bg-whitesmoke' 
            />
            {showSuggestions && (
                <ul className='absolute bg-white px-2 py-2'>
                    {filteredSuggestions?.map((suggestion, index) => (
                        <li key={index} className='text-xs font-inter cursor-pointer hover:text-dark-brown p-1' onClick={() => handleSelectedSuggestion(suggestion)}>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default Typeahead
