import { createContext, useState } from "react";

const UserStates = createContext();

const UserContext = (props) => {
    const [userList, setUserList] = useState([]);
    const [user, setUser] = useState({});

    const updateUserList = (updatedList) => {
        setUserList(updatedList);
    };

    const updateAdmin = (admin) => {
        setUser(admin);
    };

    return (
        <UserStates.Provider value={{ userList, updateUserList, user, updateAdmin }}>
            {props.children}
        </UserStates.Provider>
    );
};

export { UserStates, UserContext };