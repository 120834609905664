import { AxiosConfig } from "./AxiosConfig";


export  const getRecentOrdersApi = async(data) => {
    return await AxiosConfig.get('/dashBoard/getRecentOrders',data)
}

export const getTotalUsersApi = async(data) => {
    return await AxiosConfig.get('/dashBoard/totalUsers', data)
}

export const getTotalprofitApi = async(data) => {
    return await AxiosConfig.get('/dashBoard/totalOrderProfit', data)
}
export const getTotalOrderItemCountApi = async(data) => {
    return await AxiosConfig.get('/dashBoard/totalOrderItemCount', data)
}
export const getTotalOrderCountApi = async(data) => {
    return await AxiosConfig.get('/dashBoard/totalOrderCount', data)
}
export const getGraphData = async(data) => {
    return await AxiosConfig.get('/dashBoard/getGraph', data)
}


