import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProductStates } from "../../Context/ProductContext";
import { addAllBrands } from "../../Networking/ProductApisActions";
import Loader from "../../acsComponents/Loader";
import Dropzone from './Dropzone';

const AddBrand = () => {
  const { addBrand } = useContext(ProductStates);
  const [brandName, setBrandName] = useState("");
  const [errors, setErrors] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!brandName || !selectedFile) {
      setErrors(true);
      return;
    }

    const formData = new FormData();
    formData.append("brandName", brandName);
    if (selectedFile) {
      formData.append("files", selectedFile);
    }
    setLoading(true);

    try {
      const response = await addAllBrands(formData);
      if (response.data.code === 200) {
        addBrand(response.data.message);
        navigate("/brands", { state: { data: response.data.message } });
        setLoading(false);
      }
      if (response.data.code === 201) {
        setErrorMessage(response.data.message);
        setTimeout(() => setErrorMessage(null), 3000);
      }
      if (response.data.data.accessToken) {
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      {errorMessage && ( <p className="text-end text-xs text-red-600 font-inter font-semibold">  {errorMessage} </p>
      )}
      <div className="px-10 py-16">
        <div className="bg-white shadow-lg rounded">
          <div className="px-20 py-9">
            <h3 className="text-xs font-inter font-semibold">
              Create Brand Profile
            </h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="mt-4">
                <label className="block mb-2 text-xs font-inter font-semibold">
                  Brand Name
                </label>
                <input
                  type="text"
                  className={`w-full appearance-none ${
                    errors && !brandName
                      ? "border border-red-600"
                      : "border"
                  } rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`}
                  placeholder="Enter Brand Name"
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 mt-8">
              <label className="block mb-2 text-xs font-inter font-semibold">
                Brand Image
              </label>
              <Dropzone
                className={`px-5 py-3 border border-neutral-200 cursor-pointer ${
                  errors && !selectedFile
                    ? "border border-red-600"
                    : "border"
                }`}
                Onfilechange={handleFileChange}
              />
            </div>

            <div className="flex justify-end gap-4 mt-2 pb-8">
              <button
                className="text-gray-500 text-xs font-inter font-semibold"
                onClick={() => navigate("/brands")}
              >
                Cancel
              </button>
              <button
                className="bg-dark-brown px-4 py-1.5 text-xs font-inter text-white rounded"
                onClick={handleSubmit}
              >
                Add Brand
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBrand;
