import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Profilepic from "../../assets/profilepic.jpg";
import { getAllUsers } from "../../Networking/UserAction";
import { setUpCookie } from "../../utils/Cookie";
import Loader from "../../acsComponents/Loader";

const UserView = () => {
    const [usersData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const id = location.state.id;    

    useEffect(() => {
        setLoading(true)
        getAllUsers().then(res => {
            if (res.data.accessToken) setUpCookie(res.data.accessToken)
            setUserData(res.data.data.userResponseList);
            setLoading(false)
        })
    }, []);
      
    const selectedUser = usersData.find(item => item.id === id)

    return (
        <div className="px-10 py-14">
            {loading && <Loader />}
            <div className="bg-white p-12 shadow-md">
                <div className="">
                    {selectedUser ? (
                        <div className="grid grid-cols-1 md:flex md:items-start md:space-x-5">
                            <div className="flex justify-center md:justify-start items-start p-2">
                                <img src={Profilepic} className=" object-cover h-28 w-28" alt="" srcSet="" />
                            </div>
                            <div className="border col-span-1 md:flex-1 md:border-none flex flex-col justify-start items-start p-2">
                                <p className="font-inter font-semibold text-[25px] mt-1 uppercase">{selectedUser.name? selectedUser.name : 'N/A'}</p>
                                <div className="grid  gap-4  py-5">
                                    <div className=" justify-around grid grid-cols-3 font-inter font-medium">
                                        <div htmlFor="EmailId" className=" text-14px col-span-1">EMAIL ID</div>
                                        <div className="ml-2 col-span-2 text-black-60-opacity text-13px ">{selectedUser.email ? selectedUser.email : 'N/A'}</div>
                                    </div>
                                    <div className=" justify-around grid grid-cols-3 font-inter font-medium">
                                        <div htmlFor="EmailId" className=" text-14px col-span-1">Create Date</div>
                                        <div className="ml-2 col-span-2 text-black-60-opacity text-13px">{selectedUser.createdAt ? selectedUser.createdAt : 'N/A'}</div>
                                    </div>
                                    <div className=" justify-around grid grid-cols-3 font-inter font-medium">
                                        <div htmlFor="EmailId" className=" text-14px col-span-1">Phone</div>
                                        <div className="ml-2 col-span-2 text-black-60-opacity text-13px">{selectedUser.phone ? selectedUser.phone : 'N/A'}</div>
                                    </div>
                                    <div className=" justify-around grid grid-cols-3 font-inter font-medium">
                                        <div htmlFor="EmailId" className=" text-14px col-span-1">Remarks</div>
                                        <div className="ml-2 col-span-2">
                                            <textarea className="md:w-60 w-28 text-11px text-black-60-opacity p-2 h-max border rounded-sm border-black-30-opacity focus:outline-none  no-scrollbar" readOnly>-</textarea>
                                        </div>
                                    </div>
                                    <div className=" justify-around grid grid-cols-3 font-inter font-medium gap-3">
                                        <div htmlFor="EmailId" className=" text-14px col-span-1">Account Status</div>
                                        <div className="ml-1 col-span-2 text-black-60-opacity text-13px">{selectedUser.status ? "Active" : "In-Active"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className=" pb-2 pt-10 font-inter font-semibold text-13px flex justify-end items-center ">
                    <Link to='/userList'> <button type="submit" className="text-black-60-opacity">Cancel</button></Link>
                    <button type="submit" className="text-white bg-dark-brown rounded-sm ml-3 ps-4 pe-4 pt-1 pb-1">Save </button>
                </div>


            </div>
        </div>
    )
}

export default UserView