import { navLinks } from '../../constants/Data'
import { useContext, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { FiAlignJustify, FiArrowLeft  } from 'react-icons/fi'
import { UserStates } from '../../Context/UserContext'

const SideBar = () => {
  const [expanded, setExpanded] = useState(false)
  const [selectedLink, setSelectedLink] = useState('')
  const [openSubMenu, setOpenSubMenu] = useState(false)
  const [selectedSubMenuLink, setSelectedSubMenuLink] = useState('')
  const userContext = useContext(UserStates)  

  const variants = {
    open: { width: "230px", transition: { duration: 0.5, ease: "easeInOut" } },
    close: { width: "45px", transition: { duration: 0.5, ease: "easeInOut" } }
  }
  const buttonVariants = {
    expanded: { rotate: 0, transition: { duration: 0.3, ease: "easeInOut" } },
    collapsed: { rotate: 180, transition: { duration: 0.3, ease: "easeInOut" } }
  }
  const titleVariants = {
    open: { opacity: 1, transition: { duration: 1, ease: "easeInOut" } },
    close: { opacity: 0 }
  }

  const submenuVariants = {
    open: { height: 'auto', opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } },
    close: { height: 0, opacity: 0, transition: { duration: 0.3, ease: "easeInOut" } }
  }

  const handleSubMenu = (id) => {
    setOpenSubMenu(openSubMenu === id ? '' : id)
  }

  return (
    <motion.div initial={false} animate={ expanded ? "open" : "close" } variants={variants} className={`no-scrollbar flex flex-col h-screen bg-white shadow-xl overflow-y-auto`}>
      <div className="mt-7 flex justify-between px-5">
        <motion.p className={`font-medium font-montserrat ${expanded ? 'visible' : 'hidden'}`} animate={expanded ? 'open' : 'close'} variants={titleVariants}>Menu</motion.p>
        <motion.button onClick={() => setExpanded(!expanded)} initial={false} animate={ expanded ? "expanded" : "collapsed" } variants={buttonVariants}>{expanded ? <FiAlignJustify size={24} /> : <FiArrowLeft size={20} />}</motion.button>
      </div>

      <div className="mt-3 flex flex-col">
          {navLinks.map((nav) => (
            <div key={nav.id} className='relative'>
              <Link className={`flex px-3 pt-11px pb-11px hover:bg-dark-brown-70-opacity hover:text-white items-center space-x-3 ${selectedLink === nav.id ? 'text-dark-brown' : ''}`} to={nav.link} onClick={() => setSelectedLink(nav.id)}>
                <nav.icon size={`${expanded ? '18' : '16'}`} className='relative' />
                <motion.span onClick={() => handleSubMenu(nav.id)} className={`font-medium font-montserrat text-sm ${expanded ? 'visible' : 'hidden'}`} animate={expanded ? 'open' : 'close'} variants={titleVariants}>{nav.title}</motion.span>
                {nav.subMenu && (
                  <motion.div className={`${expanded ? 'visible' : 'hidden'}`} onClick={() => handleSubMenu(nav.id)}>
                    {openSubMenu === nav.id ? <IoIosArrowUp size={14}  /> : <IoIosArrowDown size={14} />}
                  </motion.div>
                )}
              </Link>
              <AnimatePresence>
                {nav.subMenu && openSubMenu === nav.id && (
                  <motion.ul className={`${expanded ? 'visible' : 'hidden'}`} initial="close" animate="open" exit="close" variants={submenuVariants}>
                    {nav?.subMenuItems?.map((submenu) => (
                      <li key={submenu.id} className='p-2 pl-6 hover:bg-dark-brown-70-opacity hover:text-white'>
                        <Link to={submenu.link} onClick={() => setSelectedSubMenuLink(submenu.id)} className={`font-montserrat font-medium text-xs px-5 ${selectedSubMenuLink === submenu.id ? 'text-dark-brown' : ''}`}>{submenu.title}</Link>
                      </li>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>              
            </div>          
          ))}
      </div>

      <hr className={`mt-2 border ${expanded ? 'visible' : 'hidden'}`} />
      <div className={`mt-3 p-3 flex items-center space-x-4 ${expanded ? 'visible' : 'hidden'}`}>
        <img src={'https://picsum.photos/200'} alt='dummy' width={45} height={45} className='rounded-full' />
        <div>
          <p className='font-montserrat font-semibold'>{userContext.user.username}</p>
          <p className='font-montserrat text-xs font-semibold text-gray-500'>{userContext.user.roleName}</p>        
        </div>
      </div>        
    </motion.div>
  )
}

export default SideBar