import React, { useContext } from "react";
import { useState, useEffect } from "react";
import ordersvg1 from "../../assets/svg/orders_01.svg";
import ordersvg2 from "../../assets/svg/orders_02.svg";
import ordersvg3 from "../../assets/svg/orders_03.svg";
import ordersvg5 from "../../assets/svg/orders_05.svg";
import { getRecentOrdersApi, getTotalUsersApi, getTotalprofitApi, getTotalOrderCountApi, getTotalOrderItemCountApi } from "../../Networking/DashboardApiActions";
import { DashboardStates } from "../../Context/DashboardContext";
import Loader from "../../acsComponents/Loader"
import Pagination from "../../acsComponents/Pagination";
import SearchBar from '../../acsComponents/SearchBar'
import Chart from '../../components/dashboard/Chart'

const tableHeads = ["Order ID", "Product Name", "Customer Name", "Order Date", "Delivery Date", "Status", ];

function Dashboard() {
  const [hoverdItem, setHoveredItem] = useState();
  const [loading, setLoading] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const dashboardContext = useContext(DashboardStates)
  const [orderComponent, setOrderComponent ] = useState([    
    {id:1, icon: ordersvg5, view: '', orders: 'CUSTOMERS', background:'light-green', hoverColor:'#38a5388c'}, 
    {id:2, icon: ordersvg2, view: '', orders: 'SALES PROFIT', background:'card-light-blue', hoverColor:'#ffb40082'},   
    {id:3, icon: ordersvg1, view: '', orders: 'ORDERS', background:'card-light-blue', hoverColor: '#155cd180'},
    {id:4, icon: ordersvg3, view: '', orders: 'PRODUCT SOLD', background:'card-light-blue', hoverColor:'#2200ff7d'},
  ])

  const handleSearch = (value) => setSearchInput(value.toLowerCase())
  const filteredOrders = dashboardContext.recentOrders?.filter(item => 
  item.productName.toLowerCase().includes(searchInput)
) 
  useEffect(() => {
    setLoading(true)
    getRecentOrdersApi().then(res => {
      dashboardContext.updateRecentOrders(res.data.data)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getTotalUsersApi().then((res) => {
      const data = res.data.data
      dashboardContext.updateTotalUsers(data)
      setOrderComponent(prev => prev.map(item => item.id === 1 ? { ...item, view: data } : item));   
    })
  },[])

  useEffect(() => {
    getTotalprofitApi().then((res) => {
      const data = res.data.data
      dashboardContext.updateTotalProfit(data)
      setOrderComponent(prev => prev.map(item => item.id === 2 ? {...item, view: data} : item))
    })
  },[])

  useEffect(() => {
    getTotalOrderCountApi().then((res) => {
      const data = res.data.data
      dashboardContext.updateTotalOrderCount (data)
      setOrderComponent(prev => prev.map(item => item.id === 3 ? {...item, view: data} : item))
    })
  },[])

  useEffect(() => {
    getTotalOrderItemCountApi().then((res) => {
      const data = res.data.data
      dashboardContext.updateTotalItemCount (data)
      setOrderComponent(prev => prev.map(item => item.id === 4 ? {...item, view : data} : item))
    })
  },[])
  
  return (
    <div className="pl-10 pr-10 pt-5 pb-8 h-screen overflow-auto no-scrollbar">
      {loading && <Loader />}
      <div className="flex justify-between mb-4">
        <p className="font-inter text-dark-brown text-base font-semibold">
          DASHBOARD
        </p>
        <p className=" text-right text-dark-brown text-sm font-inter font-medium">
          <span className="text-gray"> Dashboard &gt; </span> Listview
        </p>
      </div>
      <div className="grid lg:grid-cols-4 sm:grid-cols-3  md:grid-cols-3 md:gap-3 lg:gap-6 gap-2">
        {orderComponent.map((item) => (
          <div key={item.id} className={`border border-border-gray rounded-lg p-1 flex justify-center items-center h-24 group hover:translate-x-1 cursor-pointer hover:scale-105 transition-all duration-1000 ease-in-out`}
            style={{ backgroundColor: hoverdItem === item.id ? item.hoverColor : "white", }}
            onMouseEnter={() => setHoveredItem(item.id)}
            onMouseLeave={() => setHoveredItem(false)}
          >
            <div  className={`row-span-2 me-10 bg-${item.background} group-hover:bg-hover-color transition-all duration-1000 ease-in-out group-hover:p-2`}>
              <img className="p-2 w-8 h-8   duration-1000 ease-out group-hover:p-1" src={item.icon} alt="svg"  />
            </div>
            <div className=" transition-all duration-1000 ease-in-out">
              <p className="font-semibold font-inter text-lg group-hover:text-xl transition-all duration-1000 ease-in-out">{item.view}</p>
              <p className="font-medium text-xs transition-all duration-300 ease-in-out">{item.orders}</p>
            </div>
          </div>
        ))}
      </div>

      <div className='pb-2 flex justify-between items-center'>
          {loading && <Loader className={loading ? 'blur' : 'blur-none'} />}  
            <div className=' mt-4 w-80'>
            <SearchBar placeholder="Search Product Name" onInputChange={handleSearch} />
            </div>
      </div>

<div className="grid grid-cols-3 gap-2">
<div className="col-span-2" style={{boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}>
    
      <div className=" w-full h-full no-scrollbar overflow-y-auto bg-white shadow-lg rounded-b-lg">       
            <div className="sticky top-0 ">
              <div className="flex justify-center items-center">
              {tableHeads?.length > 0 ? (
                tableHeads.map((heading) => (
                  <div  key={heading} className="flex-1 flex items-center justify-center bg-light-brown h-16 font-inter text-xs text-center font-medium "
                  > {heading} </div>
                ))
              ): <div className="flex-1 flex items-center justify-center bg-light-brown h-16 font-inter text-xs text-center font-medium " >No data found
                  </div> }                
              </div>
            </div>
            <div className="p-1">
            <Pagination data={filteredOrders} justifyContent='end'>
            {filteredOrders?.length > 0 ? (
              filteredOrders.map(content => (
                <div className="border-b  h-14 flex border-border-gray-outline" >
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-dark-brown text-center">
                    {content.orderId ? content.orderId : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-center">
                    {content.productName ? content.productName : 'N/A'}
                  </div>            
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-center">
                    {content.userName ? content.userName : 'N/A'}
                  </div>                  
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-center">
                    {content.orderDate ? content.orderDate : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-center">
                    {content.deliveryDate ? content.deliveryDate : 'N/A'}
                  </div>  
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-center">
                    {content.statusName ? content.statusName : 'N/A'}
                  </div>                           
                </div>
              ))
            ) : <div className="border-b  h-16 flex border-border-gray-outline justify-center items-center text-dark-brown"
                >No products found</div>}
                </Pagination>            
            </div>        
      </div>  
      </div>

      <div className="col-span-1 p-4" style={{boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}>
        <p>Profit </p>
        <Chart />
      </div>
      </div>

      
    </div>
  );
}
export default Dashboard;