import { createContext, useState } from "react";

const OfferStates = createContext();

const OfferContext = (props) => {
    const [offerList, setOfferList] = useState([]);

    const updateOfferList = (update) => {
        setOfferList(update);
    };

    return <OfferStates.Provider value={{ offerList, updateOfferList }}>
        {props.children}
    </OfferStates.Provider>
};

export { OfferContext, OfferStates };