import { useContext, useEffect, useState } from "react"
import { IoTrashSharp, IoPencilSharp } from "react-icons/io5"
import { Link, useLocation, useNavigate } from "react-router-dom"
import SearchBar from "../../acsComponents/SearchBar"
import Pagination from "../../acsComponents/Pagination"
import Loader from "../../acsComponents/Loader"
import { setUpCookie } from "../../utils/Cookie"
import { TOKEN } from "../../utils/Constant"
import { OfferStates } from "../../Context/OfferContext"
import { getAllOffers, deleteOffers } from "../../Networking/OfferAction"

const Offer = () => {
  const [searchInput, setSearchInput] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const [localUpdatedMessage, setLocalUpdatedMessage] = useState("")
  const [localAddMessage, setLocalAddMessage] = useState("")
  
  const navigate = useNavigate()
  const location = useLocation()
  const offerContext = useContext(OfferStates)

  const updatedMessage = location.state?.data
  const addMessage = location.state?.add

  useEffect(() => {
    setLoading(true)
    getAllOffers().then(res => {
      if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
        offerContext.updateOfferList(res.data.data)
      setLoading(false)
    }).catch(() => setLoading(false))
  }, [])

  const handleSearch = (value) => {
    setSearchInput(value.toLowerCase())
  }

  useEffect(() => {
    if(searchInput) {
      const filter = offerContext.offerList.filter(item => item.offerName.toLowerCase().includes(searchInput.toLowerCase()))
      setFilteredData(filter)
    } else {
      setFilteredData(offerContext.offerList)
    }
  }, [searchInput, offerContext.offerList])

  const handleEdit = (id) => {
    navigate(`/update-offer/${id}`, { state: { id: id } })
  }

  const handleDelete = (id) => {
    setLoading(true)

    deleteOffers(id).then((res) => {
      if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
      if(res.data.code === 200) {
        setMessage(res.data.message)
        setTimeout(() => setMessage(''), 3000)
      }
      if(res.data.code === 201) {
        setError(res.data.message)
        setTimeout(() => setError(''), 3000)
      }
      offerContext.updateOfferList(prev => prev.filter(item => item.id !== id))
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (updatedMessage) {
      setLocalUpdatedMessage(updatedMessage)
      setTimeout(() => setLocalUpdatedMessage(''), 3000)
    }
    if (addMessage) {
      setLocalAddMessage(addMessage)
      setTimeout(() => setLocalAddMessage(''), 3000)
    }

  }, [updatedMessage, addMessage])
  
  return (
    <div className='px-9 py-8'>
      {loading && <Loader />}
      <h4 className='text-sm text-dark-brown font-inter font-bold'>Offers Banner</h4>
      {localUpdatedMessage && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{localUpdatedMessage}</p>}
      {localAddMessage && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{localAddMessage}</p>}
      {message && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{message}</p>}
      {error && <p className='text-end text-xs text-red-600 font-inter font-semibold'>{error}</p>}
      <div className='mt-3 w-full h-500px bg-white rounded shadow-xl'>   
        <div className='relative'>

          <div className='p-2 flex justify-between items-center'>
            <div className='ms-5 mt-4 w-80'>
              <SearchBar placeholder="Search Offer title" onInputChange={handleSearch} />
            </div>
            <Link to='/add-offer'><button className='mt-4 me-24 bg-dark-brown w-36 h-9 rounded text-xs text-white font-inter font-semibold border shadow-xl'>Add Offer</button></Link>
          </div>

          <div className='mt-3 no-scrollbar max-h-96 overflow-y-auto'>
            <div className='flex flex-col space-y-2'>
              <div className='flex bg-light-brown p-3 font-inter font-semibold text-xs text-center sticky top-0'>
                <div className='flex-1'>Offer Name</div>
                <div className='flex-1'>Image</div>
                <div className='flex-1'>Offer Title</div>
                <div className='flex-1'>Start Date</div>
                <div className='flex-1'>End Date</div>
                <div className='flex-1'>Status</div>
                <div className='flex-1'>Action</div>               
              </div>
              <Pagination data={filteredData} justifyContent='end'>
                {filteredData.length > 0 ? (filteredData.map((item, index) => (
                  <div key={index} className='flex border-b border-gray-300'>
                    <div title={item.offerName} className='flex-1 text-center py-3 font-inter text-xs text-gray-500 cursor-pointer'>{item.offerName.length > 20 ? `${item.offerName.subString(0, 20)}...` : item.offerName}</div>
                    <div className='flex-1 flex justify-center py-3 font-inter text-xs text-gray-500'>
                        <img src={item.offerImage} width={50} />
                    </div>
                    <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.title}</div>
                    <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.startsAt}</div>
                    <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.endsAt}</div>
                    <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.active ? "Active" : "Disabled"}</div>              
                    <div className='flex flex-1 justify-evenly py-3 text-gray-400 cursor-pointer'><div onClick={() => handleEdit(item.id)}><IoPencilSharp /></div><div onClick={() => handleDelete(item.id)}><IoTrashSharp /></div></div>                
                  </div>
                ))) : (
                  <div className='flex-1 text-center py-3 italic font-inter font-medium text-sm text-red-500'>No data available.</div>
                )}
              </Pagination>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Offer