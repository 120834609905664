import axios from "axios";
import { getFromCookie, setUpCookie } from '../utils/Cookie'
import { TOKEN } from "../utils/Constant";

const AuthToken =  getFromCookie('token');

const AxiosConfig = axios.create({
    baseURL: 'http://97.74.94.57:8080/BismiAdmin',
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${AuthToken}`
    }
})

AxiosConfig.interceptors.response.use(
    response => {
        if (response.data.code === 403) {
            setUpCookie(TOKEN, '');
            window.location.reload('/login')
        }
        // console.log("AxiosConfigResponse:",response);
        return response;
    },
    error => {
        if(error.response && error.response.status === 403) {
            setUpCookie(TOKEN, '');
            window.location.reload('/login');
            // alert('Session Timed out! Login Again')
        }
        // console.log("AxiosConfigError:",error);
        return Promise.reject(error)
    }
)

export { AxiosConfig };
