import { AxiosConfig } from "./AxiosConfig"

export const getAllOrders = async () => {
    return await AxiosConfig.post('/order/getAllOrder')
}

export const getOrderStatus = async () => {
    return await AxiosConfig.post('/OrderStatus/getAllOrderStatus')
}

export const trackOrders = async (data) => {
    return await AxiosConfig.post('/order/trackOrders', data)
}

export const filterOrder = async (id) => {
    return await AxiosConfig.post('/order/getOrderItems', null, {
        params : {
            orderId: id
        }
    })
}

export const updateOrderStatus = async (data) => {
    return await AxiosConfig.post('/order/updateOrderStatus', data)
}