import { createContext, useState } from "react";


const DashboardStates = createContext()

const DashboardContext = (props) => {
const [recentOrders, setRecentOrders] = useState([])
const [totalUsers, setTotalUsers] = useState([])
const [totalProfit, setTotalProfit] = useState([])
const [totalOrderCount, setTotalOrderCount] = useState([])
const [totalItemCount, setTotalItemCount] = useState([])
const [chartData, setChartData] = useState([])

const updateRecentOrders = (data) => {
    setRecentOrders(data)
}
const updateTotalUsers = (data) => {
    setTotalUsers(data) 
}
const updateTotalProfit = (data) => {
    setTotalProfit(data)
}
const updateTotalOrderCount = (data) => {
    setTotalOrderCount(data)
}
const updateTotalItemCount = (data) => {
    setTotalItemCount(data)
}
const updateChartData = (data) => {
    setChartData(data)
}

return(

<DashboardStates.Provider value={{
    recentOrders: recentOrders,
    updateRecentOrders: updateRecentOrders,
    totalUsers: totalUsers,
    updateTotalUsers: updateTotalUsers,
    totalProfit: totalProfit,
    updateTotalProfit: updateTotalProfit,
    totalOrderCount: totalOrderCount,
    updateTotalOrderCount: updateTotalOrderCount,
    totalItemCount: totalItemCount,
    updateTotalItemCount: updateTotalItemCount,
    chartData: chartData,
    updateChartData: updateChartData
    }}>
    {props.children}
</DashboardStates.Provider>
)
}

export {DashboardContext, DashboardStates}
