import React, { useContext, useEffect, useState } from 'react';
import Profilepic from '../../assets/profilepic.jpg';
import { UserStates } from '../../Context/UserContext';
import { getUser } from '../../Networking/UserAction';
import { setUpCookie } from '../../utils/Cookie';
import { TOKEN } from '../../utils/Constant';
import Loader from "../../acsComponents/Loader";
import { useLocation, useNavigate } from 'react-router-dom';

const Profile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const [responseMessage, setResponseMessage] = useState("");
    const userContext = useContext(UserStates);
    const navigate = useNavigate();
    const location = useLocation();

    const message = location.state?.message;

    useEffect(() => {
        if (message) {
            setResponseMessage(message);
            setTimeout(() => setResponseMessage(''), 3000);
        }
    }, [message]);
    

    useEffect(() => {
        setIsLoading(true);
        getUser().then(res => {
            if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken);
            userContext.updateAdmin(res.data.data);
            setData(res.data.data);
            setIsLoading(false);
        })
    }, []);

    const updateAdmin = () => {
        navigate('/profile/settings', { state: { admin: data } })
    }
    
    return (
        <div className="px-4 py-4">
            {isLoading && <Loader />}
            <div className="text-dark-brown font-bold tracking-wide text-md sm:text-md md:text-19px pb-3 ">Profile</div>
            <div className="bg-white rounded-md shadow-md pt-4 pb-4 ps-4 sm:p-6 md:pt-8 md:pb-8 md:ps-8 md:pe-32">
                {responseMessage && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{responseMessage}</p>}
                <div className="grid lg:grid-cols-4  text-black border rounded shadow-black-30-opacity ps-5 pt-5 pb-5 ">

                    <div className="lg:col-span-1 flex justify-center">
                        <img className="h-80  border object-cover" src={Profilepic} alt="Profile Picture" />
                    </div>
                    <div className="lg:col-span-3 flex flex-col">
                        <div className="ps-6">
                            <p className="text-17px sm:text-xl md:text-xl pt-3 pb-4 font-inter font-semibold uppercase">{userContext.user.username}</p>
                        </div>
                        <hr className="w-full shadow-black-30-opacity" />
                        <div className="space-y-4 lg:py-10  ps-6">
                            <div className='lg:flex lg:gap-108px'>
                                <p className="text-14px font-medium text-black-70-opacity font-inter">Address</p>
                                <div className="md:text-sm">
                                    <p className="text-14px md:text-14px text-black-70-opacity font-medium font-inter">-</p>

                                </div>
                            </div>
                            <div className='lg:flex lg:gap-28 '>
                                <p className="text-14px font-medium text-black-70-opacity font-inter">Email Id</p>
                                <p className="text-14px text-black-70-opacity font-medium font-inter">{userContext.user.email}</p>
                            </div>
                            <div className='lg:flex lg:gap-10 '>
                                <p className="text-14px font-medium text-black-70-opacity font-inter ">Mobile / Phone No</p>
                                <p className="text-14px text-black-70-opacity font-medium font-inter">{userContext.user.mobileNumber}</p>
                            </div>
                        </div>
                    </div>
                    <div className='lg:col-span-4 px-6 text-end'>
                        <button onClick={updateAdmin} className="text-xs pt-3 pb-4 font-inter font-semibold uppercase hover:text-dark-brown">Update</button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Profile;