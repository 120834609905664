import { createContext, useState } from "react"

const InvoiceStates = createContext();

const InvoiceContext = (props) => {
    const [invoiceData, setInvoiceData] = useState([])

    const updateInvoiceData = (update) => {
        setInvoiceData(update)
    }

    return (
        <InvoiceStates.Provider value={{invoiceData, updateInvoiceData}}>
            {props.children}
        </InvoiceStates.Provider>
    )
}

export { InvoiceStates, InvoiceContext }