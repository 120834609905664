import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { getOrderStatus, updateOrderStatus } from "../../Networking/OrderAction"
import { setUpCookie } from "../../utils/Cookie"
import { TOKEN } from "../../utils/Constant"
import { OrderStates } from "../../Context/OrderContext"

const UpdateItemStatus = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { itemId, itemName, status } = location.state
    const orderContext = useContext(OrderStates)    

    const [statusID, setStatusID] = useState("")
    const [errMessage, setErrMessage] = useState("")

    useEffect(() => {
        getOrderStatus().then(res => {
            if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
            orderContext.updateOrderStatus(res.data.data)
        })
    }, [])

    const handleUpdate = () => {
        const bodyData = {
            orderItemsId: itemId,
            orderStatusId: statusID,
        }

        updateOrderStatus(bodyData).then(res => {
            if (res.data.code === 200) {
                navigate(-2)
            }
            if (res.data.code === 201) {
                setErrMessage(res.data.message)
                setTimeout(() => setErrMessage(""), 3000)
            }
            if (res.data.accessToken) setUpCookie (TOKEN, res.data.accessToken)
        })
    }

    const handleSelect = (e) => {
        setStatusID(e.target.value)
    }

  return (
    <div className='px-9 py-8 h-screen overflow-auto'>
        <h3 className='text-sm text-dark-brown font-inter font-bold'>Update Item Status</h3>
        {errMessage && <p className='text-end text-xs text-red-600 font-inter font-semibold'>{errMessage}</p>}
        <div className='mt-10 w-full p-5 bg-white rounded shadow-xl'>
            {itemId || itemName !== null ? (
                <div>
                    <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
                        <div>
                            <label className='block font-inter font-semibold text-sm mb-2'>Product Name</label>
                            <input type='text' value={itemName} disabled className='w-full appearance-none border-b-2 border-dashed rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none' />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-sm mb-2'>Current Status</label>
                            <input type="text" value={status} disabled className='w-full appearance-none border-b-2 border-dashed rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none' />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-sm mb-2'>Update Status</label>
                            <select value={statusID} onChange={handleSelect} className={`w-52 border py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none rounded-md`}>
                                <option defaultChecked>Select status</option>
                                {orderContext.orderStatusList.map(item => (
                                    <option key={item.id} value={item.id}>{item.status}</option>
                                ))}
                            </select>
                        </div>                  
                    </div>
                    <div className='mt-10 flex justify-end gap-6 pb-4'>
                        <button onClick={() => navigate(-1)} className='text-xs text-gray-700 font-inter font-semibold'>Close</button>
                        <button onClick={handleUpdate} className='text-xs text-white font-inter font-semibold bg-dark-brown w-28 h-9 rounded border'>Update Status</button>                                               
                    </div>                   
                </div>
            ) : (
                <div className='flex-1 text-center py-3 font-montserrat font-medium text-base text-red-500'>No Data Available.</div>
            )}
        </div>
    </div>
  )
}

export default UpdateItemStatus