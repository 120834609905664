import React, { useContext, useEffect, useState } from 'react';
import { MultiUsers, SingleUser, RoundedUser, ph_exportbold, MdiEye } from "../../assets/svg/index"
import DropdownComponent from '../../acsComponents/DropdownComponent'
import SearchBar from '../../acsComponents/SearchBar';
import Pagination from '../../acsComponents/Pagination';
import Loader from '../../acsComponents/Loader';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../Networking/UserAction';
import { setUpCookie } from '../../utils/Cookie';
import { UserStates } from '../../Context/UserContext';

const UsersPage = () => {
    const [loading, setLoading] = useState(false)
    const navigation = useNavigate();

    const userContext = useContext(UserStates);

    const formatNumber = (num) => {
        if (num > 1000) {
            return (num / 1000) + 'K';
        } else {
            return num;
        }
    };

    useEffect(() => {
        setLoading(true);
        getAllUsers().then(res => {           
            if (res.data.accessToken) setUpCookie(res.data.accessToken);
            userContext.updateUserList(res.data.data);
            setLoading(false);
        })
    }, []);

    const navigateToView = (id) => {
        navigation("/userView", { state: { id: id } })
    }

    return (
        <div className='container md:ps-16 md:pe-16 md:pt-5 md:pb-5 md:flex md:flex-col min-w-full'>
            {loading && <Loader />}
            <div className="xl:flex flex-row justify-between gap-6 lg:flex md:grid-flow-col ">
                <div className="flex justify-between items-center border rounded-lg border-bg-gray-700 flex-1 lg:flex-0 bg-white  pt-4 pb-4 px-8">
                    <div className="leading-8">
                        <p className='font-inter font-medium text-20px ps-3'>{formatNumber(userContext.userList.totalUsersCount)}</p>
                        <p className='font-inter font-semibold text-14px ps-3 md:text-11px'>Total Users</p>
                    </div>
                    <p className='pe-5'>
                        <img src={MultiUsers} width={45} height={45} alt="Multi Users" />
                    </p>
                </div>
                <div className="flex justify-between items-center border rounded-lg border-bg-gray-700 flex-1 bg-white  pt-3 pb-3 px-8">
                    <div className="leading-8">
                        <p className='font-inter font-medium text-20px ps-3'>{formatNumber(userContext.userList.activeUsers)}</p>
                        <p className='font-inter font-semibold text-14px ps-3 md:text-11px'>Active Users</p>
                    </div>
                    <p className='pe-5'>
                        <img src={SingleUser} width={45} height={45} alt="Single User" />
                    </p>
                </div>
                <div className="flex justify-between items-center border rounded-lg border-bg-gray-700 flex-1 bg-white  pt-3 pb-3 px-8">
                    <div className="leading-8">
                        <p className='font-inter font-medium text-20px ps-3'>{formatNumber(userContext.userList.inactiveUsers)}</p>
                        <p className='font-inter font-semibold text-14px ps-3 md:text-11px'>Inactive Users</p>
                    </div>
                    <p className='pe-5 '>
                        <img src={RoundedUser} width={45} height={45} alt="Rounded User" />
                    </p>
                </div>
            </div>

            <div className='mt-4 bg-white shadow-lg min-w-full'>
                <div className="grid lg:flex justify-between items-center px-5 py-4">
                    <div className=" flex items-center">
                        <button className='border h-8 border-black-60-opacity rounded font-inter font-bold text-16px ps-3 pt-1 pb-1 pe-3 text-black-60-opacity flex items-center justify-center gap-1 ' type='submit'><img height={14} width={14} src={ph_exportbold} /> Export</button>

                        <div className="">
                            <DropdownComponent />
                        </div>
                    </div>
                    <div className="w-96 ">
                        <SearchBar />
                    </div>
                </div>
                <div className='min-w-full'>
                    <div className='sticky top-0 '>
                        <div className='flex md:flex justify-center items-center h-auto bg-light-brown text-10px md:text-13px font-inter font-medium'>
                            <div className='p-3  md:flex md:flex-1 justify-center '>User Name</div>
                            <div className='p-3  md:flex md:flex-1 justify-center '>Email</div>
                            <div className='p-3  md:flex md:flex-1 justify-center '>Create Date</div>
                            <div className='p-3  md:flex md:flex-1 justify-center '>Phone</div>
                            <div className='p-3  md:flex md:flex-1 justify-center '>Account Status</div>
                            <div className='p-3  md:flex md:flex-1 justify-center '>Action</div>
                        </div>
                    </div>
                    <div className='pb-10 no-scrollbar overflow-y-auto'>
                        <Pagination data={userContext.userList.userResponseList} justifyContent="end">
                        {userContext.userList.userResponseList && userContext.userList.userResponseList.length > 0 ? userContext.userList.userResponseList.map((item, index) => (
                            <div key={index} className='border-b border-b-gray-300 py-4 flex z-0'>
                                <div className='flex-1 text-center font-inter text-xs text-gray-500'>{item.name}</div>
                                <div className='flex-1 text-center font-inter text-xs text-gray-500'>{item.email}</div>
                                <div className='flex-1 text-center font-inter text-xs text-gray-500'>{item.createdAt}</div>
                                <div className='flex-1 text-center font-inter text-xs text-gray-500'>{item.phone}</div>
                                <div className='flex-1 text-center font-inter text-xs text-gray-500'>{item.status ? "Active" : "In-Active"}</div>
                                <div className='flex-1 flex justify-center items-center text-center font-inter hover:text-gray-400 cursor-pointer'
                                    onClick={() => navigateToView(item.id)}>
                                    <img src={MdiEye} className='h-4 cursor-pointer' alt="Users View Eye icon" />
                                </div>
                            </div>
                        )) : (
                            <div className='flex-1 text-center py-3 font-montserrat font-medium text-base text-red-500'>No data available.</div>
                        )}
                        </Pagination>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default UsersPage;