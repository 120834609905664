import { createContext, useState, } from "react";

const InventoryStates = createContext()

const InventoryContext = ({children}) => {

    const [getInventoryData, setGetInventoryData] = useState([])
    const [availabilityList, setAvailabilityList] = useState([])
    const [updateAvailabilityList, setUpdateAvailabilityList] = useState([])

    const getInventory = (updateInventory) => {
        setGetInventoryData(updateInventory)
    }

    const getAvailability = (availability) => {
        setAvailabilityList(availability)
    }

    const updateAvailability = (updateData) => {
        setUpdateAvailabilityList(updateData)
    }

    return (
        <InventoryStates.Provider value={{
                getInventoryData: getInventoryData,
                getInventory: getInventory,
                availabilityList: availabilityList,
                getAvailability: getAvailability,
                updateAvailabilityList: updateAvailabilityList,
                updateAvailability: updateAvailability,
        }}>
            {children}
        </InventoryStates.Provider>
    )


}

export {InventoryContext,InventoryStates}