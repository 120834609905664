import React, { useEffect, useState } from "react";

const tableHeads = [
  "Order ID",
  "Shipment No",
  "Customer",
  "Location",
  "Order Date",
  "Arrived Date",
  "Status",
];

function Shipment() {
  const [tableContent, setTableContent] = useState([]);

  useEffect(() => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((json) => setTableContent(json))
      .catch((error) => console.log("Error fetching data: ", error));
  }, []);

  return (
    <div className="pl-7 pr-7  pb-5 h-screen overflow-auto">    
      <p className="font-inter text-dark-brown text-base font-semibold mt-10 mb-7">SHIPMENTS</p>      
 <div
        className="rounded-lg bg-white"
        style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
      >
        <div className="xl:flex md:flex 2xl:flex items-center flex-nowrap xl:flex-wrap pt-8 pb- md:flex-wrap ">        
        <div className="flex 2xl:flex-nowrap xl:flex-nowrap md:flex-nowrap flex-wrap"> 
            <div className="p-2 xl:px-1.5 2xl flex border-2 ml-8 opacity-50 border-border-gray-outline  bg-white rounded-lg hover:border-blue-400  items-center w-80 ">
              <input
                className="focus:outline-none w-full"
                type="text"
                placeholder="search order id"
              />
            </div>
            <div className="ml-8  w-32 border-2 border-border-gray-outline rounded ">
              <div className=" flex items-center p-2">
                <button className="flex items-center opacity-50 ">All</button>               
              </div>
            </div>   
            </div>

              <div className="flex 2xl:flex-nowrap xl:flex-nowrap md:flex-nowrap flex-wrap sm:mt-1 ">       
            <div className="flex items-center 2xl:flex-wrap sm:flex-nowrap">
              <label className="font-inter text-sm ml-8">From: </label>
              <input
                className="ml-4 border-2 border-border-gray-outline rounded-sm pt-2.5 pb-2.5 pl-3 pe-3 text-xs"
                type="date"
                placeholder=""
              />
            </div>
            <div className="flex flex-row items-center md:p-1">
              <label className=" flex-col font-inter text-sm ml-8">
                To:
              </label>
              <input
                className="ml-4 border-2 border-border-gray-outline rounded-sm pt-2.5 pb-2.5 pl-3 pe-3 text-xs p-1"
                type="date"
                placeholder=""
              />
            </div>
        </div>
        </div>

        <div className=" w-full mt-4 no-scrollbar overflow-y-auto bg-white shadow-lg rounded-b-lg h-96 ">       
            <div className="sticky top-0 ">
              <div className="flex justify-center items-center">
                {tableHeads.map((heading) => (
                  <div
                    key={heading}
                    className="flex-1 flex items-center justify-center bg-light-brown h-16 font-inter text-xs text-center font-medium "
                  >
                    {heading}
                  </div>
                ))}
              </div>
            </div>
            <div>
              {tableContent.map((content) => (
                <div
                  key={content}
                  className="border-b  h-16 flex border-border-gray-outline"
                >
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-dark-brown">
                    {content.id}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-dark-brown">
                    {content.name}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                    {content.username}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                    {content.name}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                    {content.username}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                    {content.name}
                  </div>
                  <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                    {content.name}
                  </div>
                </div>
              ))}
            </div>        
        </div>        
      </div>
    </div>
  );
}
export default Shipment;
