import { useContext, useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import OfferDropZone from './OfferDropZone'
import Loader from "../../acsComponents/Loader"
import { setUpCookie } from "../../utils/Cookie"
import { TOKEN } from "../../utils/Constant"
import { addOffer } from "../../Networking/OfferAction"
import { ProductStates } from "../../Context/ProductContext"
import { getAllProducts } from "../../Networking/ProductApisActions"

const AddOffer = () => {

    const [offerName, setOfferName] = useState("")
    const [offerImage, setOfferImage] = useState("")
    const [offerTitle, setOfferTitle] = useState("")
    const [description, setDescription] = useState("")
    const [startDate, setStartDate] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [productCategory, setProductCategory] = useState("")
    const [errors, setErrors] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errMessage, setErrMessage] = useState(false)

    const navigate = useNavigate()
    const productContext = useContext(ProductStates)

    const handleSelect = (e) => {
      setProductCategory(e.target.value)
    }

    const handleFile = (url) => setOfferImage(url)

    useEffect(() => {
      setLoading(true)
      getAllProducts().then(res => {
        if (res.data.code === 200) {
          setUpCookie('TOKEN', res.data.accessToken)
          productContext.updateCategory(res.data.data)
          setLoading(false)
        }
        if (res.data.code === 204) {
          setErrMessage(res.data.message)
          setLoading(false)
        }
      })

    }, [])
    
    const hanldeSubmit = () => {
      if(offerName.length === 0 || offerTitle.length === 0 || startDate.length === 0 || dueDate.length === 0 || description.length === 0 || subTitle.length === 0 || productCategory.length === 0 || offerImage.length === 0) {
        return setErrors(true)
      }
        
      const resetValues = () => {
        setOfferName("")
        setOfferTitle("")
        setDescription("")
        setStartDate("")
        setDueDate("")
        setOfferImage("")
        setErrors(false)
        setLoading(false)
      }

      const bodyData = new FormData()
      bodyData.append("file", offerImage)

      const params = {
        offerName: offerName,
        offerImage: null,
        title: offerTitle,
        subTitle: subTitle,
        description: description,
        category: productCategory,
        startsAt: startDate,
        endsAt: dueDate,
        active: true,
      }
      addOffer(bodyData, params).then(response => {
        setLoading(true)
        console.log(response)
        if (response.data.code === 200) {
          resetValues()
          navigate('/offers', { state: { add: response.data.message } })
          setLoading(false)
        }
        if (response.data.code === 201) {
          setErrMessage(true)
          setTimeout(() => setErrMessage(''), 3000)
          setLoading(false)
        }
        if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken)
      }).catch((err) => console.log(err))
    }

  return (
    <div className='px-4 py-14 sm:px-9'>
      {loading && <Loader />}
      <div className='mt-3 w-full bg-white rounded shadow-lg h-500px no-scrollbar overflow-y-scroll'>
        <div className='px-20 py-8'>
          <p className='font-inter font-semibold text-xs'>Add Offer Banner</p>
          {errMessage && <p className='text-end text-xs text-red-600 font-inter font-semibold'>{errMessage}</p>}
          <div className='mt-5 grid grid-cols-1 gap-6 lg:grid-cols-2 mb-4'>
            <div>
              <label className='block font-inter font-semibold text-xs mb-2'>Offer Name</label>
              <input type='text' value={offerName} className={`w-full appearance-none ${errors && !offerName ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Offer Name' onChange={(e) => setOfferName(e.target.value)} />
            </div>
            <div>
              <label className='block font-inter font-semibold text-xs mb-2'>Offer Title</label>
              <input type='text' value={offerTitle} className={`w-full appearance-none ${errors && !offerTitle ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Enter Offer Title' onChange={(e) => setOfferTitle(e.target.value)} />
            </div>
          </div>

          <div className='mt-5 grid grid-cols-1 lg:grid-cols-4 gap-4'> 
            <div>
              <label className='block font-inter font-semibold text-xs mb-2'>Sub Title</label>
              <input type='text' value={subTitle} className={`w-full ${errors && !subTitle ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} onChange={(e) => setSubTitle(e.target.value)} placeholder='Enter Sub Title' />
            </div>
            <div>
              <label className='block font-inter font-semibold text-xs mb-2'>Product Category</label>
              <select value={productCategory} onChange={handleSelect} className={`w-full ${errors && !productCategory ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none cursor-pointer`}>
                <option defaultChecked>Select Category</option>
                {productContext.categoryList.map(item => (
                  <option key={item.id} value={item.id}>{item.categoryName}</option>
                ))}
              </select>
            </div>
            <div>
              <label className='block font-inter font-semibold text-xs mb-2'>Start Date</label>
              <input type='date' className={`w-full ${errors && !startDate ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none cursor-pointer`} onChange={(e) => setStartDate((e.target.value).split('-').reverse().join('-'))} />
            </div>
            <div>
              <label className='block font-inter font-semibold text-xs mb-2'>End Date</label>
              <input type='date' className={`w-full ${errors && !dueDate ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none cursor-pointer`} onChange={(e) => setDueDate((e.target.value).split('-').reverse().join('-'))} />
            </div>
          </div>

          <div className='mt-5 grid grid-cols-1 lg:grid-cols-4 gap-4'>
            <div>
              <label className='block font-inter font-semibold text-xs mb-2'>Description</label>
              <textarea value={description} className={`w-full appearance-none ${errors && !description ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Enter Description' onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className='lg:col-span-2'>
              <label className='block font-inter font-semibold text-xs mb-2'>Offer Image</label>
              <OfferDropZone className={`py-4 px-2 border ${errors && !offerImage ? 'border-red-500' : 'border-neutral-200'} cursor-pointer`} OnFilechange={handleFile}/>
            </div>
          </div>

          <div className='mt-2 flex justify-end gap-6 pb-4'>
            <Link to='/offers'><button className='text-xs text-gray-700 font-inter font-semibold'>Close</button></Link>
            <button className='text-xs text-white font-inter font-semibold bg-dark-brown w-28 h-9 rounded border' onClick={hanldeSubmit}>Add Offer</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddOffer