import React, { useState } from "react";

const SearchBox = ({
  placeholder = "Enter your search Keyword",
  onInputChange,
  corners,
  height,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isHover, setIsHover] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (onInputChange) {
      onInputChange(value);
    }
  };

  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  return (
    <div
      style={{
        display: "flex",
        borderRadius: "8px",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
          <svg
        style={{  marginLeft: 15, marginTop: 5, position: 'relative', zIndex:1, left:'40px' }}
        width="28"
        height="28"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M20.0245 21.3971L13.848 15.2206C13.3578 15.6127 12.7941 15.9232 12.1569 16.152C11.5196 16.3807 10.8415 16.4951 10.1225 16.4951C8.3415 16.4951 6.83431
 15.8781 5.60098 14.6441C4.36765 13.4101 3.75065 11.9029 3.75 10.1225C3.75 8.3415 4.36699 6.83431 5.60098 5.60098C6.83497 4.36765 8.34216 3.75065 10.1225 
 3.75C11.9036 3.75 13.4111 4.36699 14.6451 5.60098C15.8791 6.83497 16.4958 8.34216 16.4951 10.1225C16.4951 10.8415 16.3807 11.5196 16.152 12.1569C15.9232 
 12.7941 15.6127 13.3578 15.2206 13.848L21.3971 20.0245L20.0245 21.3971ZM10.1225 14.5343C11.348 14.5343 12.3899 14.1056 13.248 13.248C14.1062 12.3905 
 14.535 11.3487 14.5343 10.1225C14.5343 8.89706 14.1056 7.85556 13.248 6.99804C12.3905 6.14052 11.3487 5.71144 10.1225 5.71078C8.89706 5.71078 7.85556
  6.13987 6.99804 6.99804C6.14052 7.85621 5.71144 8.89771 5.71078 10.1225C5.71078 11.348 6.13987 12.3899 6.99804 13.248C7.85621 14.1062 8.89771 14.535 10.1225 14.5343Z"
            fill="black"
          />
        </g>
      </svg>
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          fontSize: "13.5px",
          fontFamily: "Inter",
          width: "100%",
          border: `1px solid lightgray`,
          borderRadius: `${corners}px`,
          height: `${height}px`,
          textAlign: "start",
          paddingTop: "8px",
          paddingBottom: "8px",
          paddingLeft: "45px",
          paddingRight: "10px",
          cursor: "pointer",
          transition: "border-color 1s",
          color: "gray",
          outline: "none",
          position: "relative",
          borderColor: isHover ? "#3983b3" : "lightgray",
        }}
      />


    </div>
  );
}

export default SearchBox;