import React from 'react'

const InvoiceCard = ({ backgroundColor, image, item }) => {
  return (
    <div className='px-8 py-4 rounded shadow' style={{ backgroundColor: backgroundColor }}>
        <div className='flex justify-between items-center pb-1'>
            <div className='flex flex-col gap-3'>
                <p className='font-inter font-semibold text-lg'>{item?.totalcount}</p>
                <p className='font-inter font-bold text-xs'>{item?.name}</p>
            </div>
            <img src={image} alt='invoice-icons' width={25} height={25} className='me-7' />
        </div>      
    </div>
  )
}

export default InvoiceCard