import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadRounded, deletered } from '../../assets/svg/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { editUser } from '../../Networking/UserAction';
import Loader from '../../acsComponents/Loader';

const ProfileSettings = () => {
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errMessage, setErrMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.admin;

    useEffect(() => {
        setFirstName(data?.username);
        setEmail(data?.email);
        setPhoneNumber(data?.mobileNumber);
    }, [data]);

    const onDrop = useCallback((uploadedFiles) => {
        setAcceptedFiles(uploadedFiles);
    }, []);

    const NumberFormat = (num) => {
        if (num >= 1000) {
            return (num / 1000).toFixed(2) + 'k';
        } else {
            return num.toFixed(2);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleRemoveUpload = (fileToRemove) => {
        setAcceptedFiles(acceptedFiles.filter(file => file !== fileToRemove));
    };

    const handleUpdateProfile = () => {
        setIsLoading(true);
        const nameRegex = /^[a-zA-Z]+$/;
        const emailRegex = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9.-]+\.[a-z]{2,3}$/;
        const phoneRegex = /^[0-9]{10}$/;
        let errors = {};

        if (firstName.trim() === '' || !nameRegex.test(firstName.trim())) {
            errors.firstName = 'The First Name is required and should contain only letters';
        }
        if (lastName.trim() === '' || !nameRegex.test(lastName.trim())) {
            errors.lastName = 'The Last Name is required and should contain only letters';
        }
        if (phoneNumber.trim() === '' || !phoneRegex.test(phoneNumber.trim())) {
            errors.phoneNumber = 'The Phone Number is required and should be 10 digits';
        }
        if (email.trim() === '') {
            errors.email = 'The Email ID is required';
        } else if (!emailRegex.test(email.trim())) {
            errors.email = 'The Email ID is invalid';
        }

        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            setIsLoading(false);
            return;
        }

        const bodyData = {
            username: `${firstName} ${lastName}`,
            email: email,
            roleId: data.roleId,
            mobileNumber: phoneNumber
        } 

        editUser(bodyData).then(res => {
            if (res.data.code === 200) {
                navigate('/profile', { state: { message: res.data.message } });
                setIsLoading(false);
            }
            if (res.data.code === 201) {
                setErrMessage(res.data.message);
                setTimeout(() => setErrMessage(''), 3000);
                setIsLoading(false)
            }
        })
    };

    return (
        <div className='px-4 py-2  sm:px-8 sm:py-6 md:px-12 md:py-1 h-full'>
            {isLoading && <Loader />}
            <div className="text-17px text-end font-medium tracking-wide mb-3">
                <p className='text-black-60-opacity pt-2'>Accounts &#62; <span className='text-dark-brown'>Settings</span></p>
            </div>
            <div className="bg-white shadow-md shadow-slate-300 px-14 py-10 w-full">
                <div className="mb-6 font-inter font-semibold text-16px">Profile Settings</div>
                {errMessage && <p className='text-end text-xs text-red-600 font-inter font-semibold'>{errMessage}</p>}
                <div className="shadow-slate-300 shadow border-border-color-code p-5 grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div className="md:col-span-1">
                        <p className='text-13px font-medium font-inter'>First Name<span className='text-confirm-red ms-1'>*</span></p>
                        <input
                            className='w-full border border-border-color-code p-2 rounded focus:outline-border-color-code font-inter'
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        { errors.firstName &&   <p className='text-confirm-red capitalize text-xs mt-1 ms-3'>{errors.firstName}</p>}
                    </div>
                    <div className="md:col-span-1">
                        <p className='text-13px font-medium font-inter'>Last Name<span className='text-confirm-red ms-1'>*</span></p>
                        <input
                            className='w-full focus:outline-border-color-code border border-border-color-code p-2 rounded font-inter'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        {errors.lastName && <p className='text-confirm-red capitalize text-xs mt-1 ms-3'>{errors.lastName}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className='text-13px font-medium font-inter'>Email Address<span className='text-confirm-red ms-1'>*</span></p>
                        <input
                            className='w-full focus:outline-border-color-code border border-border-color-code p-2 rounded font-inter'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.email && <p className='text-confirm-red capitalize text-xs mt-1 ms-3'>{errors.email}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className='text-13px font-medium font-inter'>Phone Number<span className='text-confirm-red ms-1'>*</span></p>
                        <input
                            type='number'
                            className='w-full focus:outline-border-color-code border border-border-color-code p-2 rounded font-inter'
                            onChange={
                                (e) => setPhoneNumber(e.target.value)
                            }
                            value={phoneNumber}
                        />
                        {errors.phoneNumber && <p className='text-confirm-red capitalize text-xs mt-1 ms-3'>{errors.phoneNumber}</p>}
                    </div>
                    <div className="col-span-1">
                        <p className='text-13px font-medium font-inter'>Address<span className='text-confirm-red ms-1'></span></p>
                        <textarea
                            type='text'
                            className='w-full focus:outline-border-color-code border p-2 border-border-color-code rounded min-h-24'
                        />
                    </div>

                    <div className="col-span-1">
                        <p className='text-13px font-medium font-inter'>Profile Picture<span className='text-confirm-red ms-1'></span></p>
                        <div className='grid lg:flex justify-start md:min-h-24 gap-3'>
                            <div {...getRootProps()} className='cursor-pointer border border-border-color-code p-3 md:p-5 rounded-md lg:min-w-60'>
                                <input {...getInputProps()} />
                                <div className='space-y-3'>
                                    <div className="grid place-content-center items-center">
                                        <img className='text-black-60-opacity' height={15} width={15} src={UploadRounded} alt="Upload Icon" />
                                    </div>
                                    <p className=' text-black-60-opacity text-13px font-normal place-content-center'>Drop files here or click to upload</p>
                                </div>
                            </div>
                            {acceptedFiles.length > 0 && (
                                <div className="max-h-lvh md:h-min border px-2 py-1.5 rounded border-border-color-code md:max-w-full">
                                    <div>
                                        {acceptedFiles.map((file) => (
                                            <div key={file.path} className='space-y-1 flex lg:flex md:gap-5 gap-2 justify-start items-center'>
                                                <img src={URL.createObjectURL(file)} alt="Uploaded File" className='object-fill h-10 w-16' />
                                                <p className='grid text-wrap text-9px md:text-sm font-normal md:font-medium'>
                                                    {file.path}
                                                    <br />
                                                    Size: {NumberFormat(file.size)}
                                                </p>
                                                <p className='cursor-pointer' onClick={() => handleRemoveUpload(file)}>
                                                    <img className='object-contain h-5' src={deletered} alt="Delete" />
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="pt-7 pb-7 md:pt-2 col-span-1 md:col-span-2">
                        <div className="flex justify-center items-center md:justify-end md:items-end">
                            <button onClick={handleUpdateProfile} className='bg-dark-brown text-white rounded-md text-13px font-bold font-inter ps-3 pe-3 pt-1 pb-1 h-max'>Update Profile</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileSettings;
