import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InventoryStates } from "../../Context/InventoryContext";
import { IoIosArrowDown } from "react-icons/io";
import Loader from '../../acsComponents/Loader'
import { getAllAvailabiltyApi, updateAvailabilityApi } from '../../Networking/InventoryApiActions';

const UpdateAvailability = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { productId, productName, imageURL, brand } = location.state;
  const [open, setOpen] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState({id: null, type: '' });
  const [message, setMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const inventoryContext = useContext(InventoryStates);

  useEffect(() => {
    const getAvailabilities = () => {
      setLoading(true)
      getAllAvailabiltyApi().then((response) => {
        const data = response.data.data;
        inventoryContext.getAvailability(data);
        setLoading(false)
      });
    };
    getAvailabilities();
  }, []);

  const handleOptionClick = (option) => {
    setSelectedAvailability(option); 
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();  
    if (selectedAvailability.id) {
      const bodyData = {
        availabilityStatusId: selectedAvailability.id,
        productId: productId,
      };   
      setLoading(true) 
       updateAvailabilityApi(bodyData).then(response => {
        if(response.data.code === 200) {
          setMessage(response.data.message);
          setLoading(false)
          setTimeout(() => setMessage(null),3000);   
          setSelectedAvailability({ id: null, type: '' });              
        }
        if (response.data.code === 201) {
          setErrorMessage(response.data.message)
          setTimeout(() => setErrorMessage(''), 3000)
        }
      })     
  };  
} 

  return (
    <div>
  {loading && <Loader className={loading ? 'blur' : 'blur-none'} />}
    <div className="pt-6 pr-2">
    {message && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{message}</p>}
    {errorMessage && <p className='text-end text-xs text-red-600 font-inter font-semibold'>{errorMessage}</p>}
    </div>
    <div className="flex justify-center items-center w-full bg-gray-100 mt-6">  
      <div className="w-3/6 ">
        <div className="flex justify-between w-full pl-2 pr-2 pb-2 border-b">
          <p className="text-dark-brown">Update Availability Status</p>
        </div>
        <div className="w-full h-auto bg-white p-6 pt-8">
          <div className="flex items-center justify-between px-7">
            <div className="flex flex-col gap-2">
              <p><span className="text-dark-brown">Brand:</span> {brand}</p>
              <p><span className="text-dark-brown">Product Name:</span> {productName}</p>
            </div>
            <div className="border p-2">
              <img src={imageURL} alt="mainImage" className="w-48 h-40"/>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="relative w-1/2 ml-6 mb-12 mt-5">
              <label className="block mb-2 text-dark-brown">Update Availability Status</label>
              <div className="flex items-center relative">
                <input
                  onClick={() => setOpen(!open)}
                  type="text"
                  className="w-full p-2 border outline-none cursor-pointer"
                  placeholder="Select Availability"
                  value={selectedAvailability.type} 
                  readOnly
                  required
                />
                <IoIosArrowDown className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={() => setOpen(!open)} />
              </div>
              {open && (
                <div className="absolute top-full left-0 w-full bg-white border mt-1">
                  {inventoryContext.availabilityList.map((option, index) => (
                    <div
                      key={index}
                      className="p-1 hover:bg-gray-200 cursor-pointer"
                      onClick={() => handleOptionClick(option)}
                    >
                      {option.type}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-darkbrown border px-3 py-1 bg-dark-brown text-white"
              >
                Save
              </button>
              <button
                type="button"
                className="border-2 ml-2 px-3 py-1 hover:bg-dark-brown hover:text-white rounded-sm cursor-pointer"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default UpdateAvailability
