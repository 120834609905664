import { AxiosConfig } from "./AxiosConfig";

export const getAllOffers = async () => {
    return await AxiosConfig.post('/Offers/getOffers');
};

export const addOffer = async (data, params) => {
    return await AxiosConfig.post('/Offers/addOffers', data, {
        params: {
            ...params
        },
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
    });
};

export const deleteOffers = async (id) => {
    return await AxiosConfig.post('/Offers/deleteOffers', null, {
        params: {
            id: id
        }
    });
};

export const updateOffers = async (data, params) => {
    return await AxiosConfig.post('/Offers/updateOffers', data, {
        params: {
            ...params
        },
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
    });
};