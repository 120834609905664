import React from "react";

const ListSelection = (props) => {
    return <div style={{ width: '95%', position: 'relative', display: 'flex', alignItems: 'flex-start', backgroundColor: props.selectedCategory === props.item ? '#96754e': '#FFFFFF', paddingTop: 10, paddingBottom: 10, flexDirection: 'column' }}
    onClick={() => {props.onClick(props.item)}}>
        {
            props.children
        }

        {
            props.selectedCategory === props.item ? <div style={{
                width: 16, height: 16, backgroundColor: '#FFFFFF', position: 'absolute', right: '-7.5px',
                transform: 'rotate(45deg)', backgroundColor: '#96754e', zIndex: 10
            }}></div> : null
        }
    </div>
}

export default ListSelection;