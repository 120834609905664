import React, { useState } from "react";

function Pagination({ children, justifyContent }) {
  const [currentPage, setCurrentPage] = useState(1);
  const perPageItem = 5;
  const pageNumberLimit = 2;
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [maxpageNumberLimit, setMaxPageNumberLimit] = useState(3);
  const childrenArray = React.Children.toArray(children);
  const noOfPages = Math.ceil(childrenArray.length / perPageItem);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxpageNumberLimit) {
      setMaxPageNumberLimit(maxpageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxpageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const firstPage = () => {
    setCurrentPage(1);
    setMaxPageNumberLimit(pageNumberLimit);
    setMinPageNumberLimit(0);
  };

  const lastPage = () => {
    setCurrentPage(noOfPages);
    setMaxPageNumberLimit(noOfPages);
    setMinPageNumberLimit(noOfPages - pageNumberLimit);
  };

  const firstItemIndex = (currentPage - 1) * perPageItem;
  const lastItemIndex = Math.min(
    currentPage * perPageItem,
    childrenArray.length
  );
  const slicedChildren = childrenArray.slice(firstItemIndex, lastItemIndex);

  let pageIncrementBtn = null;
  if (noOfPages > maxpageNumberLimit) {
    pageIncrementBtn = <li style={{ marginLeft: "3px" }}> &hellip; </li>;
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li style={{ marginLeft: "3px" }}> &hellip; </li>;
  }

  const renderPageNumbers = Array.from({ length: noOfPages }, (_, index) => {
    const number = index + 1;
    if (number > minPageNumberLimit && number <= maxpageNumberLimit) {
      return (
        <div
          className={currentPage === number ? "active" : null}
          onClick={handleClick}
          style={{
            padding: "10px",
            height: "15px",
            width: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "3px",
            border: "1px solid rgb(189, 174, 174)",
            marginLeft: "6px",
            backgroundColor: currentPage === number ? "#703F07" : "white",
            color: currentPage === number ? "white" : "black",            
          }}
          key={number}
          id={number}
        >
          {number}
        </div>
      );
    } else {
      return null;
    }
  });

  return (
    <div>
      {slicedChildren}
      <div
        style={{
          display: "flex",
          justifyContent: justifyContent,
          marginTop: "5px",
          marginRight: "15px",
        }}
      >
        <div style={{ listStyle: "none", display: "flex" }}>
          <div
            style={{
              padding: "10px",
              height: "15px",
              width: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "3px",
              border: "1px solid rgb(189, 174, 174)",
              marginLeft: "6px",
            }}
          >
            <button
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "13px",
                fontWeight: "bold",
                color: "rgb(112, 63, 7)",
              }}
              onClick={firstPage}
              disabled={currentPage === 1}
            >
              &#8810;
            </button>
          </div>
          <div
            style={{
              padding: "10px",
              height: "15px",
              width: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "3px",
              border: "1px solid rgb(189, 174, 174)",
              marginLeft: "6px",
            }}
          >
            <button
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "13px",
                fontWeight: "400",
                color: "rgb(112, 63, 7)",
              }}
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
          </div>
          {pageDecrementBtn}
          <div style={{ display: "flex" }}>{renderPageNumbers}</div>
          {pageIncrementBtn}
          <div
            style={{
              padding: "10px",
              height: "15px",
              width: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "3px",
              border: "1px solid rgb(189, 174, 174)",
              marginLeft: "6px",
            }}
          >
            <button
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "13px",
                fontWeight: "400",
                color: "rgb(112, 63, 7)",
              }}
              onClick={nextPage}
              disabled={currentPage === noOfPages}
            >
              &#62;
            </button>
          </div>
          <div
            style={{
              padding: "10px",
              height: "15px",
              width: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "3px",
              border: "1px solid rgb(189, 174, 174)",
              marginLeft: "6px",
            }}
          >
            <button
              style={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                fontSize: "13px",
                fontWeight: "bold",
                color: "rgb(112, 63, 7)",
              }}
              onClick={lastPage}
              disabled={currentPage === noOfPages}
            >
              &#8811;
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pagination;