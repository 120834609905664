import { createContext, useState } from "react";

const OrderStates = createContext();

const OrderContext = (props) => {
    const [orderList, setOrderList] = useState([])
    const [orderStatusList, setOrderStatusList] = useState([])

    const updateOrderList = (updatedStatus) => {
        setOrderList(updatedStatus)
    }

    const updateOrderStatus = (updatedStatus) => {
        setOrderStatusList(updatedStatus)
    }

    return (
        <OrderStates.Provider value={{ orderList, setOrderList, updateOrderList, orderStatusList, setOrderStatusList, updateOrderStatus }}>
            {props.children}
        </OrderStates.Provider>
    )
}

export { OrderStates, OrderContext }