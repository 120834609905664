import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Dashboard from "./components/dashboard/Dashboard";
import Brand from "./components/brand/Brand";
import Login from "./components/login/Login";
import ModalAddbrand from "./components/brand/ModalAddbrand";
import { AuthContext, AuthUser } from "./Context/AuthUserContext";
import { ProductContext } from "./Context/ProductContext";
import { getFromCookie } from "./utils/Cookie";
import Coupons from "./components/coupons/Coupons";
import Transaction from "./components/transaction/Transaction";
import CreateTransaction from "./components/transaction/CreateTransaction";
import AddCoupon from "./components/coupons/AddCoupon";
import ViewTransaction from "./components/transaction/ViewTransaction";
import ViewCoupon from "./components/coupons/ViewCoupon";
import Invoice from "./components/invoice/Invoice";
import CreateInvoice from "./components/invoice/CreateInvoice";
import Profile from "./components/accounts/Profile";
import ProfileSettings from "./components/accounts/ProfileSettings";
import Inventory from "./components/Inventory/Inventory";
import Users from "./components/users/Users";
import OrderOverview from "./components/orders/OrderOverview";
import OrderList from "./components/orders/OrderList";
import Shipment from "./components/shipments/Shipment";
import UserView from "./components/users/UserView";
import UpdateAddBrand from "./components/brand/UpdateAddbrand";
import AddProducts from "./components/Product/AddProducts";
import AddProductColor from "./components/Product/AddProductColor";
import AddProductSize from "./components/Product/AddProductSize";
import AddProductUnit from "./components/Product/AddProductUnit";
import ForgotPassword from "./components/login/ForgotPassword";
import { CouponContext } from "./Context/CouponContext";
import EditCoupon from "./components/coupons/EditCoupon";
import GetProducts from "./components/Product/GetProducts";
import { InventoryContext } from "./Context/InventoryContext";
import UpdateAvailability from "./components/Inventory/UpdateAvailability";
import { OrderContext } from "./Context/OrderContext";
import UpdateItemStatus from "./components/orders/UpdateItemStatus";
import { TransactionContext } from "./Context/TransactionContext";
import { UserContext } from "./Context/UserContext";
import { DashboardContext } from "./Context/DashboardContext";
import Chart from './components/dashboard/Chart'
import { InvoiceContext } from "./Context/InvoiceContext";
import ResetPassword from "./components/accounts/ResetPassword";
import { OfferContext } from "./Context/OfferContext";
import Offer from "./components/offer/Offer";
import AddOffer from "./components/offer/AddOffer";
import UpdateOffer from "./components/offer/UpdateOffer";

function AppComponent() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <BrowserRouter>
      {isAuthenticated ? (
        <div className="flex">
          <Sidebar />
          <main className="w-full">
            <Routes>
              <Route path="/" exact element={<Dashboard />} />
              <Route path="/brands" exact element={<Brand />} />
              <Route path="/Addbrand" exact element={<ModalAddbrand />} />
              <Route path="/userList" exact element={<Users />} />
              <Route path="/inventory" exact element={<Inventory />} />
              <Route path="/profile" exact element={<Profile />} />
              <Route path="/profile/settings" exact element={<ProfileSettings />} />
              <Route path="/coupons" exact element={<Coupons />} />
              <Route path="/addCoupon" exact element={<AddCoupon />} />
              <Route path="/viewCoupon/:id" exact element={<ViewCoupon />} />
              <Route path="/editCoupon/:id" exact element={<EditCoupon />} />
              <Route path="/transactions" exact element={<Transaction />} />
              <Route path="/createTransactions" exact element={<CreateTransaction />} />
              <Route path="/viewTransactions" exact element={<ViewTransaction />} />             
              <Route path="/invoice" exact element={<Invoice />} />
              <Route path="/createInvoice" exact element={<CreateInvoice />} />
              <Route path="/OrderList" exact element={<OrderList />} />
              <Route path="/orderOverview" exact element={<OrderOverview />} />
              <Route path="/update-itemStatus/:id" exact element={<UpdateItemStatus />} />            
              <Route path="/shipping" exact element={<Shipment />} />
              <Route path="/UserView" exact element={<UserView />} />
              <Route path="/UpdateAddBrand" exact element={<UpdateAddBrand />} />
              <Route path="/products/getproducts" exact element={<GetProducts />} />
              <Route path="/products/AddProduct" exact element={<AddProducts />} />
              <Route path="/products/AddProductColor" exact element={<AddProductColor />} />
              <Route path="/products/AddProductSize" exact element={<AddProductSize />} />
              <Route path="/products/AddProductUnit" exact element={<AddProductUnit />} />
              <Route path="/updateAvailability/:id" exact element={<UpdateAvailability />} />
              <Route path="/chart" exact element={<Chart />} />
              <Route path="/profile/reset-password" exact element={<ResetPassword />} />
              <Route path="/offers" exact element={<Offer/>} />
              <Route path="/add-offer" exact element={<AddOffer />} />
              <Route path="/update-offer/:id" exact element={<UpdateOffer />} />
            </Routes>
          </main>
        </div>
      ) : (
        <Routes>
          <Route path="/login" exact element={<Login />} />
          <Route path="/forgot-password" exact element={<ForgotPassword />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

const App = () => {
  return (
    <AuthUser>
      <ProductContext>
        <InventoryContext>
          <CouponContext>
            <OrderContext>
              <TransactionContext>
                <UserContext>
                  <DashboardContext>
                    <InvoiceContext>
                      <OfferContext>
                        <AppComponent />
                      </OfferContext>
                    </InvoiceContext>
                  </DashboardContext>
                </UserContext>
              </TransactionContext>      
            </OrderContext>
          </CouponContext>
        </InventoryContext>
      </ProductContext>
    </AuthUser>
  );
};

export default App;
