import React, { useContext } from "react";
import { useState, useEffect } from "react";
import ordersvg1 from "../../assets/svg/orders_01.svg";
import ordersvg2 from "../../assets/svg/orders_02.svg";
import ordersvg3 from "../../assets/svg/orders_03.svg";
import ordersvg4 from "../../assets/svg/orders_04.svg";
import ordersvg5 from "../../assets/svg/orders_05.svg";
import download from "../../assets/svg/download.svg";
import { filterOrder, getAllOrders } from "../../Networking/OrderAction";
import Loader from "../../acsComponents/Loader"
import { OrderStates } from "../../Context/OrderContext";
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";
import Pagination from "../../acsComponents/Pagination";
import { useNavigate } from "react-router-dom";

const tableHeads = ["Order ID", "Shipment No", "Customer", "Location", "Order Date", "Price", "Status"];

function OrderList() {

  const [loading, setLoading] = useState(false)

  const orderContext = useContext(OrderStates)
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    getAllOrders().then(res => {
      if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
      orderContext.updateOrderList(res.data.data)
      setLoading(false)
    })
  }, [])  

  const handleViewOrder = (id, orderId) => {
    filterOrder(id).then(res => {
      if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
      navigate('/orderOverview', { state: { order: res.data.data, orderId } })
    })
  }

  return (
    <div className="pl-10 pr-10 pt-5 pb-8 h-screen overflow-auto">
      {loading && <Loader />}
      <div className="flex justify-between mb-4">
        <p className="font-inter text-dark-brown text-base font-semibold">
          ORDERS LIST
        </p>
        <p className=" text-right text-dark-brown text-sm font-inter font-medium">
          <span className="text-gray"> orders &gt; </span> Listview
        </p>
      </div>

      <div className="grid lg:grid-cols-5 sm:grid-cols-3 md:grid-cols-3 md:gap-3 lg:gap-4 gap-2">
        <Card background="#FFFFFF" hoverColor="#155CD180" icon={ordersvg1} data={orderContext.orderList?.noOfNewOrders} name="NEW ORDERS" />
        <Card background="#FFFFFF" hoverColor="#FFB40082" icon={ordersvg2} data={orderContext.orderList?.noOfPendingOrders} name="PENDING ORDERS" />
        <Card background="#FFFFFF" hoverColor="#38A5388C" icon={ordersvg3} data={orderContext.orderList?.noOfDeliveryOrders} name="DELIVERY ORDERS" />
        <Card background="#FFFFFF" hoverColor="#2200FF7D" icon={ordersvg4} data={orderContext.orderList?.noOfPickUpOrders} name="PICKUP ORDERS" />
        <Card background="#FFFFFF" hoverColor="#FA06067A" icon={ordersvg5} data={orderContext.orderList?.noOfCancelledOrders} name="CANCELLED ORDERS" />
      </div>

      <div className="mt-6 grid grid-cols-5 grid-flow-row  ">
        <div className="md:col-span-4 col-span-3 border-2 border-gray-border rounded-lg md:h-10 h-8 "></div>
        <div className="col-span-1 flex justify-center items-center">
          <div className="text-white bg-dark-brown w-max cursor-pointer flex items-center font-semibold text-sm md:text-base pt-1 pb-1 ps-3 pe-3 rounded-sm md:ml-0 ml-16">
            <span className="md:mr-3 mr-0">
              <img src={download} alt="download icon" />
            </span>
            Download
          </div>
        </div>
      </div>

      <div className=" w-full mt-4 no-scrollbar overflow-y-auto bg-white shadow-lg rounded-b-lg h-80">
        <div className="flex flex-col space-y-2">
          <div className="flex bg-light-brown p-3 font-inter font-semibold text-xs text-center sticky top-0">
            {tableHeads.map((heading) => (
              <div key={heading} className="flex-1">{heading}</div>
            ))}
          </div>
        </div>
        <Pagination data={orderContext.orderList?.orderResponseList} justifyContent='end'>
          {orderContext.orderList?.orderResponseList && orderContext.orderList?.orderResponseList.length > 0 ? (orderContext.orderList.orderResponseList?.map(item => (
            <div key={item.id} className='flex border-b border-gray-300'>
               <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500 cursor-pointer underline underline-offset-4' onClick={() => handleViewOrder(item.orderId, item.id)}>{item.orderId}</div>
               <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'></div>
               <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.userName ? item.userName : 'N/A'}</div>
               <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.address ? item.address : 'N/A'}</div>
               <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.createdAt ? item.createdAt : 'N/A'}</div>
               <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.finalAmount ? item.finalAmount : 'N/A'}</div>
               <div className='flex-1 text-center py-3 font-inter text-xs text-gray-500'>{item.orderStatus ? item.orderStatus : 'N/A'}</div>
            </div>
          ))) : (
            <div className='flex-1 text-center py-3 italic font-inter font-medium text-base text-red-500'>No data available.</div>
          )}

        </Pagination>
      </div>
      
    </div>
  );
}
export default OrderList;


const Card = (props) => {
  return (
    <div className={`border border-border-gray rounded-lg p-1 flex justify-center items-center h-24 group bg-white hover:translate-x-1 cursor-pointer hover:scale-105 transition-all duration-1000 ease-in-out`} style={{ backgroundColor: props.background }} onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = props.hoverColor)} onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = props.background)}>
      <div className='row-span-2 me-3 group-hover:bg-hover-color transition-all duration-1000 ease-in-out group-hover:p-2'>
        <img className='p-2 w-8 h-8 duration-1000 ease-out group-hover:p-1' src={props.icon} alt='order-icons' />
      </div>
      <div className='transition-all duration-1000 ease-in-out'>
        <p className='font-semibold font-inter text-lg group-hover:text-xl transition-all duration-1000 ease-in-out'>{props.data}{" "}</p>
        <p className='font-medium text-xs transition-all duration-300 ease-in-out'>{props.name}{" "}</p>
      </div>
    </div>
  )
}