import {  useContext, useState } from 'react';
import login from '../../assets/login.png';
import { setUpCookie } from '../../utils/Cookie';
import axios from 'axios';
import Loader from '../../acsComponents/Loader';
import { AuthContext } from '../../Context/AuthUserContext';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [responseError, setResponseError] = useState('');
    const { setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    const validateForm = () => {
        const emailRegex = /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9.-]+\.[a-z]{2,3}$/;
        let formErrors = {};
        if (!username) {
            formErrors.username = "Username is required.";
        } else if (!emailRegex.test(username.trim())) {
            formErrors.username = "Invalid email format.";
        }
        if (!password) {
            formErrors.password = "Password is required.";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        const bodyData = {
            email: username,
            password: password
        };
        setLoading(true);

        axios.post('http://97.74.94.57:8080/BismiAdmin/auth/login', bodyData)
            .then(response => {
                if (response.status === 200) {
                    setUpCookie('token', response.data);
                    setResponseError('');
                    setIsAuthenticated(true);
                    navigate('/');
                }
                setLoading(false);
                console.log('response', response);
            })
            .catch(error => {
                setResponseError('Login failed. Please check your credentials and try again.');
                setLoading(false);
                setIsAuthenticated(false)
                navigate('/login');
            })
    };


    return (
        <div className='flex justify-center items-center min-h-screen bg-cover bg-center' style={{ backgroundImage: `url(${login})` }}>
            {loading && <Loader />}
            <div className='w-full max-w-md p-4 md:p-6 lg:p-8 rounded bg-white shadow-xl'>
                <p className='mt-1 text-center font-inter text-dark-brown font-semibold text-lg'>Login to Luxorè Delights</p>
                <form className='px-4 md:px-6 lg:px-8 pt-6' onSubmit={handleSubmit}>
                    <div className='mb-4'>
                        <label className='block text-xs font-inter font-semibold mb-2'>Email</label>
                        <input
                            type='text'
                            className='w-full appearance-none border py-2 px-3 text-gray-700 text-xs focus:outline-none bg-whitesmoke'
                            placeholder='Your Username / Email ID'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        {errors.username && <p className='text-xs text-red-600'>{errors.username}</p>}
                    </div>
                    <div className='mb-4'>
                        <label className='block text-xs font-inter font-semibold mb-2'>Password</label>
                        <input
                            type='password'
                            className='w-full appearance-none border py-2 px-3 text-gray-700 text-xs focus:outline-none bg-whitesmoke'
                            placeholder='Your Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {errors.password && <p className='text-xs text-red-600'>{errors.password}</p>}
                    </div>
                    <div className='flex justify-between'>
                        <div className='flex items-center space-x-2'>
                            <input
                                type='checkbox'
                                className='cursor-pointer rounded'
                                checked={rememberMe}
                                onChange={() => setRememberMe(!rememberMe)}
                            />
                            <span className='text-gray-400 font-inter text-xs font-semibold'>Remember Me</span>
                        </div>
                        <Link to="/forgot-password"><p className='underline underline-offset-4 font-inter font-semibold text-xs text-dark-brown'>Forgot Password?</p></Link>
                    </div>
                    {responseError && <p className='text-xs text-red-600 mt-2'>{responseError}</p>}
                    <div className='mt-8 flex justify-center pb-8'>
                        <button
                            type='submit'
                            className='w-full max-w-64 h-9 border bg-dark-brown text-white text-xs font-inter font-medium'
                            disabled={loading}
                        >
                            {loading ? 'Logging in...' : 'LOGIN'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
