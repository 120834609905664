import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Dropzone from './Dropzone'
import { addCoupon } from "../../Networking/CouponAction"
import Loader from "../../acsComponents/Loader"
import { setUpCookie } from "../../utils/Cookie"
import { TOKEN } from "../../utils/Constant"

const AddCoupon = () => {

    const [couponTitle, setCouponTitle] = useState("")
    const [couponCode, setCouponCode] = useState("")
    const [startDate, setStartDate] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [amount, setAmount] = useState("")
    const [errors, setErrors] = useState(false)
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [maxDiscount, setMaxDiscount] = useState("")
    const [minAmount, setMinAmount] = useState("")
    const [noOfTimes, setNoOfTimes] = useState("")
    const [loading, setLoading] = useState(false)
    const [errMessage, setErrMessage] = useState(false)

    const navigate = useNavigate()

    const handleFile = (url) => setImage(url)
    
    const hanldeSubmit = () => {
        if(couponTitle.length === 0 || couponCode.length === 0 || startDate.length === 0 || dueDate.length === 0 || amount.length === 0 || maxDiscount.length === 0 || description.length === 0 || minAmount.length === 0 || noOfTimes.length === 0 || image.length === 0 ) {
          return setErrors(true)
        }

        const resetValues = () => {
            setAmount('')
            setCouponCode('')
            setCouponTitle('')
            setStartDate('')
            setDueDate('')
            setDescription('')
            setMaxDiscount('')
            setMinAmount('')
            setNoOfTimes('')
            setImage('')
            setErrors(false)
            setLoading(false)
        }

        const bodyData = new FormData()
        bodyData.append('file', image)

        const params = {
            couponCode: couponCode,
            offerName: couponTitle,
            description: description,
            discount: amount,
            maximumDiscount: maxDiscount,
            minimumPurchaseAmount: minAmount,
            startDate: startDate,
            endDate: dueDate,
            noOfTimes: noOfTimes,
            active: true,
            bankOffer: false
        }
        addCoupon(bodyData, params).then(response => {
            setLoading(true)
            if (response.data.code === 200) {
                resetValues()
                navigate('/coupons', { state: { add: response.data.message } })
                setLoading(false)
            }
            if (response.data.code === 201) {
                setErrMessage(true)
                setLoading(false)
                setTimeout(() => setErrMessage(''), 3000)
            }
            if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken)

        }).catch(() => setLoading(false))
    }

  return (
    <div className='px-4 py-14 sm:px-9'>
        {loading && <Loader />}
        <div className='mt-3 w-full bg-white rounded shadow-lg h-500px no-scrollbar overflow-y-scroll'>
            <div className='px-20 py-8'>
                <p className='font-inter font-semibold text-xs'>Add Coupon</p>
                {errMessage && <p className='text-end text-xs text-red-600 font-inter font-semibold'>Coupon already exist</p>}
                    <div className='mt-5 grid grid-cols-1 gap-6 lg:grid-cols-2 mb-4'>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Coupon Title</label>
                            <input type='text' className={`w-full appearance-none ${errors && !couponTitle ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Coupon Title' onChange={(e) => setCouponTitle(e.target.value)} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Coupon Code</label>
                            <input type='text' className={`w-full appearance-none ${errors && !couponCode ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Enter Coupon Code' onChange={(e) => setCouponCode(e.target.value)} />
                        </div>
                    </div>

                    <div className='mt-5 grid grid-cols-1 lg:grid-cols-4 gap-4'>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Description</label>
                            <textarea className={`w-full appearance-none ${errors && !description ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Enter Description' onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Start Date</label>
                            <input type='date' className={`w-full ${errors && !startDate ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none cursor-pointer`} onChange={(e) => setStartDate((e.target.value).split('-').reverse().join('-'))} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>End Date</label>
                            <input type='date' className={`w-full ${errors && !dueDate ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none cursor-pointer`} onChange={(e) => setDueDate((e.target.value).split('-').reverse().join('-'))} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Amount/Percentage</label>
                            <input type='number' className={`w-full appearance-none ${errors && !amount ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Enter Amount/Percentage' onChange={(e) => setAmount(e.target.value)}/>
                        </div>
                    </div>

                    <div className='mt-5 grid grid-cols-1 lg:grid-cols-4 gap-4'>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Minimum Amount</label>
                            <input type='number' className={`w-full ${errors && !minAmount ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} onChange={(e) => setMinAmount(e.target.value)} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Maximum Amount</label>
                            <input type='number' className={`w-full ${errors && !maxDiscount ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} onChange={(e) => setMaxDiscount(e.target.value)} />
                        </div>
                        <div className='lg:col-span-2'>
                            <label className='block font-inter font-semibold text-xs mb-2'>Coupon Image</label>
                            <Dropzone className={`py-4 px-2 border ${errors && !image ? 'border-red-500' : 'border-neutral-200'} cursor-pointer`} OnFilechange={handleFile} />
                        </div>          
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-4'>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>No Of Times</label>
                            <input type='number' className={`w-full ${errors && !noOfTimes ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} onChange={(e) => setNoOfTimes(e.target.value)}/>
                        </div>
                    </div>

                    <div className='mt-2 flex justify-end gap-6 pb-4'>
                        <Link to='/coupons'><button className='text-xs text-gray-700 font-inter font-semibold'>Close</button></Link>
                        <button className='text-xs text-white font-inter font-semibold bg-dark-brown w-28 h-9 rounded border' onClick={hanldeSubmit}>Add Coupon</button>
                    </div>
            </div>
        </div>               
    </div>
  )
}

export default AddCoupon