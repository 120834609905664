import React, { useEffect, useState, useContext } from "react";
import { disableProduct, getAllInventoryProducts } from "../../Networking/InventoryApiActions";
import { InventoryStates } from "../../Context/InventoryContext";
import SearchBar from '../../acsComponents/SearchBar';
import { IoTrashSharp, IoPencilSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";
import Pagination from "../../acsComponents/Pagination";
import Loader from "../../acsComponents/Loader";

const GetProducts = () => {
  const navigate = useNavigate();
  const inventoryContext = useContext(InventoryStates);
  const [searchInput, setSearchInput] = useState("");
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const handleSearch = (value) => setSearchInput(value.toLowerCase());

  const filteredProducts = products?.filter(item =>
    item.product.toLowerCase().includes(searchInput)
  );

  const tableHeads = [
    "Product Name", "Product Brand", "Product Color", "Product Size", 
    "Product Unit", "Discount Price", "Rating", "Main Image", "Action"
  ];

  useEffect(() => {
    const getData = () => {
      getAllInventoryProducts().then((response) => {
        const data = response?.data?.data;
        setProducts(data);
        inventoryContext.getInventory(data); 
        setLoading(false);
      });
    };
    getData();
  }, [inventoryContext]);

  const handleEdit = (productId, productName, imageURL, brand) => {
    navigate(`/updateAvailability/${productId}`, { state: { productId, productName, imageURL, brand } });
  };

  const handleDelete = (id) => {
    const bodyData = { requestId: id };
    setLoading(true);
    disableProduct(bodyData).then(res => {
      if (res.data.code === 200) {
        setMessage(res.data.message);
        setProducts(prev => prev.filter(item => item.id !== id));
        setTimeout(() => setMessage(""), 3000);
      } else if (res.data.code === 201) {
        setErrMessage(res.data.message);
        setTimeout(() => setErrMessage(''), 3000);
      }
      if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken);
      setLoading(false);
    });
  };

  return (
    <div className="p-3">
      {loading && <Loader className={loading ? "blur" : "blur-none"} />}
      {message && <p className='text-end text-xs text-green-600 font-inter font-semibold'>{message}</p>}
      {errMessage && <p className='text-end text-xs text-red-600 font-inter font-semibold'>{errMessage}</p>}
      <div className="mt-10 w-2/5">
        <SearchBar placeholder="Search product Name" onInputChange={handleSearch} />
      </div>   
      <div className="w-full mt-4 no-scrollbar overflow-y-auto bg-white shadow-lg rounded-b-lg h-96">       
        <div className="sticky top-0 ">
          <div className="flex justify-center items-center">
            {tableHeads?.length > 0 ? (
              tableHeads.map((heading) => (
                <div key={heading} className="flex-1 flex items-center justify-center bg-light-brown h-16 font-inter text-xs text-center font-medium"> {heading}
                </div>
              ))
            ) : (
              <div className="flex-1 flex items-center justify-center bg-light-brown h-16 font-inter text-xs text-center font-medium "
              > No data found </div>
            )}
          </div>
        </div>
        <div>
          <Pagination data={filteredProducts} justifyContent='end'>
            {filteredProducts?.length > 0 ? (
              filteredProducts.map((content) => (
                <div key={content.id} className="border-b h-14 flex border-border-gray-outline">
                  <div className="flex-1 flex justify-center items-center font-inter text-xs text-dark-brown">
                    {content.product ? content.product : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center font-inter text-xs">
                    {content.brand ? content.brand : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center font-inter text-xs">
                    {content.color ? content.color : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center font-inter text-xs">
                    {content.productSize ? content.productSize : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center font-inter text-xs">
                    {content.unit ? content.unit : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center font-inter text-xs">
                    {content.discountedPrice ? content.discountedPrice : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center font-inter text-xs">
                    {content.rating ? content.rating : 'N/A'}
                  </div>
                  <div className="flex-1 flex justify-center items-center font-inter text-xs ">
                    <img src={content.mainImage} alt='Main Image' className="h-10 w-12" />
                  </div>                      
                  <div className='flex flex-1 justify-evenly py-3 text-gray-400 cursor-pointer items-center'>
                    <div onClick={() => handleEdit(content.productId, content.product, content.mainImage, content.brand)}>
                      <IoPencilSharp />
                    </div>
                    <div onClick={() => handleDelete(content.productId)}>
                      <IoTrashSharp />
                    </div>
                  </div>               
                </div>
              ))
            ) : (
              <div className="border-b h-16 flex border-border-gray-outline items-center justify-center text-dark-brown">
                <p>No data found</p>
              </div>
            )}
          </Pagination>
        </div>   
      </div>  
    </div>
  );
};
export default GetProducts