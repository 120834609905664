import { useState, useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { Upload } from "../../assets/icons"

const Dropzone = ({ className, OnFilechange, defaultImage = null }) => {
    const [files, setFiles] = useState("")
    const [fileRemoved, setFileRemoved] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const onDrop = useCallback(acceptedFiles => {
        if(acceptedFiles?.length) {
            const newFile = acceptedFiles[0]
            setFiles(Object.assign(newFile, { preview: URL.createObjectURL(newFile) }))
            OnFilechange(newFile)
            setFileRemoved(false)
            setErrorMessage("")
        }
    }, [OnFilechange])

    const onDropRejected = useCallback(rejectedFile => {
        if (rejectedFile?.length) {
            setErrorMessage("This is not a valid file format. Please upload a JPEG or PNG image.")
        }
    })

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        onDropRejected,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png']
        },
        maxFiles: 1
    })

    const removeFile = () => {
        setFiles("")
        OnFilechange("")
        setFileRemoved(true)
        setErrorMessage("")
    }

    useEffect(() => {
        if (defaultImage) {
            return setFiles({ preview: defaultImage })
        }
    }, [defaultImage])
  
    return (
        <div className='flex items-center gap-4'>
            <div {...getRootProps({ className: className })}>
                <input {...getInputProps()} />
                {
                isDragActive ?
                    <p className='font-inter' style={{ fontSize: '9px' }}>Drop the files here ...</p> :
                    <div className='flex flex-col items-center gap-3'>
                        <img src={Upload} alt="CouponImage" className='w-3 h-3' />
                        <p className='font-inter text-center' style={{ fontSize: '9px' }}>Drop the files here or click to upload</p>
                    </div>
                }
            </div>
            {files ? (
                <div className='border border-neutral-200 py-4 px-2 flex items-center justify-between gap-6'>
                    <div className='flex items-center gap-2'>
                        <img src={files.preview} alt='Offer' className='object-contain' width={45} height={15} />
                        <div className='flex flex-col'>
                            <span className='text-xs font-semibold font-inter'>{files.name}</span>
                        </div>
                    </div>
                    <button className='p-0.5 bg-red-600 text-white font-inter' style={{ fontSize: '9px' }} onClick={removeFile}>Delete</button>
                </div>
            ) : (
                fileRemoved && !errorMessage ? (
                    <p className="pt-3 text-red-600 text-xs font-inter italic">Please upload a image</p>
                ) : null
            )}
            {errorMessage && <p className="pt-3 text-red-600 text-xs font-inter italic">{errorMessage}</p>}
        </div>
      
    )
  }

export default Dropzone