import { createContext, useState } from "react";

const CouponStates = createContext();

const CouponContext = (props) => {
    const [couponList, setCouponList] = useState([]);
    const [couponID, setCouponID] = useState({});

    const updateCouponList = (updatedCoupon) => {
        setCouponList(updatedCoupon);
        // const getCouponID = {};
        // updatedCoupon.forEach(coupon => getCouponID[coupon.id] = coupon);
        // setCouponID(getCouponID);
        const getCouponID = updatedCoupon.reduce((acc, coupon) => {
            acc[coupon.id] = coupon;
            return acc;
        }, {})
        setCouponID(getCouponID);
    }

    return (
        <CouponStates.Provider value={{ couponList, updateCouponList, setCouponList, couponID }}>
            {props.children}
        </CouponStates.Provider>
    );
};

export { CouponStates, CouponContext };
